export const popUpStyle = {
    marginBottom15: {
        marginBottom: '15px',
      },
      width100: {
        width: "100%",
      },
      resetButton: {
        backgroundColor: "#ffffff",
        color: "#322b7c",
        border: "2px solid #322b7c",
        borderRadius: "5px",
        width: "150px",
        boxShadow: "none",
        marginRight: "10px",
        fontWeight: "600",
        fontFamily: "Poppins",
        fontStyle: "normal",
        textAlign: "center",
        fontSize: 12.7,
        "&:hover": {
          background: "none",
        },
      },
      closeIcon: {
        color: "#1c1939",
      },
      dialogContent: {
        justifyContent: "center",
        marginTop: 15,
      },
      twocolumnlayout: {
        display: "flex",
        justifyContent: "space-between",
        height:'auto'
      },
      width90: {
        width: "90%",
      },
      textField: {
        border: "transparent",
        borderRadius: 15,
        width: "100%",
        padding: "0px",
      },
      padding5: {
        padding: "5px",
      },
      addcss: {
        color: "#1c1939",
        fontStyle: "normal",
        textAlign: "left",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Poppins",
      },
      headingcss: {
        color: "#1c1939",
        fontWeight: 800,
        fontFamily: "Poppins",
        fontStyle: "normal",
        textAlign: "left",
        fontSize: "18px",
      },
      labelSizing: {
        fontSize: "12px",
        color: "#1c1939",
        fontWeight: 800,
        marginBottom: "10px",
        marginLeft: "7px",
      },
      saveButton: {
        textAlign: "center",
        fontSize: 12,
        color:'#fff',
        "&:hover": {
          background: "#322b7c",
        },
        backgroundColor: "#322b7c",
        borderRadius: "5px",
        width: "150px",
        boxShadow: "none",
        marginLeft: "10px",
        fontWeight: "600",
        fontFamily: "Poppins",
        fontStyle: "normal",
       
      },
      labelSizingAntSwitch:{
        color:"#322b7c",
        fontSize: "12px",
        fontWeight: 800,
      },
}