import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { routeSelector, routeStateActions } from '@app/router/redux';
import { navbarComponentName } from '@app/router/redux/routeHandler';
import { AccessManagementNavBar } from './access-management';
import { HomeNavBar } from './home';
import { UserGroupManagementNavBar } from './user-group-management';
import { PlanAndMeetNavBar } from './plan-and-meet';
import { DcrUnlockNavBar } from './dcr-unlock-request';
import { PositionManagementNavbar } from './position-management';
import { ApprovalWorkflowNavbar } from './approval-workflow';
import { IntransitChallanNavBar } from './intransit-challan';
import { CNFApprovalRequestNavbar } from './cnf-approval';
import { InTransitAccessNavBar } from './in-transit-access';
import { InventoryConfiguratorNavBar } from './inventory-configurator';
import { ExpectedUtilizationDateNavBar } from './expected utilization date';
import { InventoryRoles } from './inventory-roles';
import { CommonNavBar } from './commonheader';
import { ComponentManagementBar } from './component-management';
import { ConfigurationManagementNavBar } from './configuration-management';
import { ExpenseNavBar } from './expense';
import { MasterDataManagementBar } from './master-data-management';
import { ModuleSpecificUtilitiesBar } from './module-specific-utilities';
import { StatusManagerNavBar } from './module-specific-utilities/status-manager';
import { ApproveExpensesNavBar } from './module-specific-utilities/approve-expense';
import DirectoryNavBar from './directory-configuration';
import AdminNavBar from './admin-configuration';

const styles = {
  toolbar: {
    backgroundColor: '#dde8ec',
    color: '#1e1c3b',
    padding: '20px',
    borderRadius: '20px',
  },
};

const AppBar: any = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})((props: any) => ({
  transition: props.theme.transitions.create(['margin', 'width'], {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: 'transparent',
  boxShadow: 'none',
  ...(!props.open && {
    width: 'calc(100% - 240px)',
    marginRight: 240,
  }),
  ...(props.open && {
    width: 'calc(100% - 513px)',
    marginRight: 272,
    borderRadius: 20,
    transition: props.theme.transitions.create(['margin', 'width'], {
      easing: props.theme.transitions.easing.easeOut,
      duration: props.theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const util: any = {
  handleDrawerOpen: null,
  paddingChanges: null,
  navbarComponent: null,
};
export const NavBar = ({ authorized }: { authorized?: boolean }) => {
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(routeSelector.getSideBarOpen(), shallowEqual);
  const navbarComponentNameSelector = useSelector(
    routeSelector.getNavbarComponentName(),
    shallowEqual,
  );
  const handleDrawerOpen = () => {
    dispatch(routeStateActions.setSideBarOpen(true));
  };
  const paddingChanges = () => {
    if (navbarComponentNameSelector === navbarComponentName.home) {
      return '50px';
    } else {
      if (navbarComponentNameSelector === navbarComponentName.adminConfiguration)
        return '20px 20px 0px 20px'
      else {
        return '20px';
      }
    }
  };

  const expenseAdminNavBar = () => {
    if (navbarComponentNameSelector === navbarComponentName.Expense) {
      return <ExpenseNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.MasterDataManagement
    ) {
      return <MasterDataManagementBar />;
    } else if (
      navbarComponentNameSelector ===
      navbarComponentName.moduleSpecificUtilities
    ) {
      return <ModuleSpecificUtilitiesBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.ComponentManagement
    ) {
      return <ComponentManagementBar />;
    } else if (
      navbarComponentNameSelector ===
      navbarComponentName.ConfigurationManagement
    ) {
      return <ConfigurationManagementNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.commonheader
    ) {
      return <CommonNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.statusManager
    ) {
      return <StatusManagerNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.approveExpenses
    ) {
      return <ApproveExpensesNavBar />;
    }
  };
  const inventoryNavBar = () => {
    if (
      navbarComponentNameSelector === navbarComponentName.inTransitChallan
    ) {
      return <IntransitChallanNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.cnfReturnItems
    ) {
      return <CNFApprovalRequestNavbar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.inventoryConfigurator
    ) {
      return <InventoryConfiguratorNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.inTransitAccess
    ) {
      return <InTransitAccessNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.inventoryRules
    ) {
      return <InventoryRoles />;
    } else if (
      navbarComponentNameSelector ===
      navbarComponentName.expectedUtilizationDate
    ) {
      return <ExpectedUtilizationDateNavBar />;
    }
  }
  const navbarComponent = () => {
    if (navbarComponentNameSelector === navbarComponentName.accessManagement) {
      return <AccessManagementNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.accessGroupMaster
    ) {
      return <UserGroupManagementNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.planAndMeet
    ) {
      return <PlanAndMeetNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.dcrUnlockRequest
    ) {
      return <DcrUnlockNavBar />;
    } else if (navbarComponentNameSelector === navbarComponentName.home) {
      return <HomeNavBar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.positionManagement
    ) {
      return <PositionManagementNavbar />;
    } else if (
      navbarComponentNameSelector === navbarComponentName.approvalWorkflow
    ) {
      return <ApprovalWorkflowNavbar />;
    } else if (navbarComponentNameSelector === navbarComponentName.directoryConfiguration) {
      return <DirectoryNavBar />;
    } else if (navbarComponentNameSelector === navbarComponentName.adminConfiguration) {
      return <AdminNavBar />;
    }
    return inventoryNavBar();

  };

  util.handleDrawerOpen = handleDrawerOpen;
  util.paddingChanges = paddingChanges;
  util.navbarComponent = navbarComponent;

  return (
    <AppBar position="fixed" open={sideBarOpen}>
      <Toolbar sx={[styles.toolbar, { padding: paddingChanges() }]}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(sideBarOpen ? { display: 'none' } : '') }}>
          <MenuIcon />
        </IconButton>

        {authorized ? navbarComponent() ?? expenseAdminNavBar() : ''}
      </Toolbar>
    </AppBar>
  );
};
