import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import {
  adminActions,
  adminSelector,
} from "@app/screens/admin-configuration/redux";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useStyles } from '@app/screens/Styles/style';
import { useEffect, useState } from "react";
import { CREDIT_NOTE_TABS } from "@app/common/constants";
import { adminusergroupCreator, creditNoteManagemnetSelector, getDashboardCountCreator } from "@app/screens/admin-configuration/pages/creditnote-management/redux";
import { appSelector } from "@app/store/selectors";

export const util: any = {
  handleClose: null,
  handleChange: null,
  handleExpenseApprovedDialog: null,
  handleExpenseApprovedDialogClose: null,
  handleApproveExpenseDialogOk: null,
  handleChangeDADeductAmount: null,
  handleApproveExpenseDialogCancel: null,
};

const AdminNavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState<number>(0);
  const adminusergroup = useSelector(creditNoteManagemnetSelector.getadminusergroup(), shallowEqual);
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const getDashboardCount = useSelector(creditNoteManagemnetSelector.getDashboardCount(), shallowEqual);


  useEffect(() => {
    dispatch(adminusergroupCreator({
      staffPositionId: userInfo?.staffPositionId
    }))
  }, [userInfo?.staffPositionId])

  const handleChange = (_event: any, newValue: number): void => {
    setValue(newValue);
    dispatch(
      adminActions.updateNavData({
        tabNumber: newValue,
        title: 'Credit Note Management',
      }),
    );
  };
  useEffect(() => {
    dispatch(
      adminActions.updateNavData({
        tabNumber: 0,
        title: 'Credit Note Management',
      }),
    );
    dispatch(
      getDashboardCountCreator({})
    )
  }, [])

  util.handleChange = handleChange;

  const title = useSelector(adminSelector.getNavTitle());
  return (
    <Grid container justifyContent={'space-between'} sx={{ minHeight: 110 }}>
      <Grid item xs={8}>
        <Box sx={{
          mb: 4,
          mt: 1,
        }}>
          <Typography
            variant="title"
            color={'primary.main'}

          >
            {title}
          </Typography>
        </Box>
      </Grid>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="approve expense tabs"
            className="creditNoteHeaderTabs"
          >
            {
              CREDIT_NOTE_TABS?.filter((tabItem: any) => tabItem.roles.includes(String(adminusergroup?.adminGroup)))?.map((tabItem: any, key: any) =>
                <Tab
                  label={`${tabItem?.name} 
                  ${(tabItem?.isCountKey && getDashboardCount?.[tabItem?.isCountKey]) ? '(' + getDashboardCount?.[tabItem?.isCountKey] + ')' : ''}`}
                  value={tabItem?.tabIndex}
                  key={key}
                />
              )
            }
          </Tabs>
        </Box>
      </Box>
    </Grid>
  );
}

export default AdminNavBar;
