import { createAction, createSlice } from "@reduxjs/toolkit";

interface InitialStateProps {
  creditNote: {
    id: number;
    tabId: number;
    name: string;
  }[];
  summaryDashboard: any[],
  partyProducts: any[],
  discountList: any[],
  approvalMatrix: any[],
  cnRequests: any[],
  requestDetails: any[],
  dashboardCount: any[],
  adminusergroup: any[],
  productList: any[],
  statehqlist: any[],
  MRList: any[],
  stockList: any[],
  divisionList: any[],
  templateFile: any[],
  screenLoader: any,
}

export const initialState: InitialStateProps = {
  creditNote: [],
  summaryDashboard: [],
  partyProducts: [],
  discountList: [],
  approvalMatrix: [],
  cnRequests: [],
  requestDetails: [],
  dashboardCount: [],
  adminusergroup: [],
  productList: [],
  statehqlist: [],
  MRList: [],
  stockList: [],
  divisionList: [],
  templateFile: [],
  screenLoader: false
};

export const updateCreditNoteCreator = createAction<any>(
  "ADMIN/CREDIT_NOTE_MANAGEMENT"
);
export const updateCreditNoteCreatorTypeName = updateCreditNoteCreator(null).type;

export const updateSummaryDashboardCreator = createAction<any>(
  "ADMIN/SUMMARY_DASHBOARD"
);
export const updateSummaryDashboardTypeName = updateSummaryDashboardCreator(null).type;

export const adminusergroupCreator = createAction<any>(
  "ADMIN/ADMIN_GROUP"
);
export const adminusergroupTypeName = adminusergroupCreator(null).type;


export const updatePartyProductsCreator = createAction<any>(
  "ADMIN/PARTY_PRODUCTS"
);
export const updatePartyProductsTypeName = updatePartyProductsCreator(null).type;

export const updateDiscountListCreator = createAction<any>(
  "ADMIN/DISCOUNT_LIST"
);
export const updateDiscountListTypeName = updateDiscountListCreator(null).type;

//productList
export const productListCreator = createAction<any>(
  "ADMIN/PRODUCT_LIST"
);
export const productListTypeName = productListCreator(null).type;

export const templateFileCreator = createAction<any>(
  "ADMIN/TEMPLATE_FILE"
);
export const templateFileTypeName = templateFileCreator(null).type;


export const fileDownloadCreator = createAction<any>(
  "ADMIN/FILE_DOWNLOAD"
);
export const fileDownloadTypeName = fileDownloadCreator(null).type;

export const updateApprovalMatrixCreator = createAction<any>(
  "ADMIN/APPROVAL_MATRIX"
);
export const updateApprovalMatrixTypeName = updateApprovalMatrixCreator(null).type;

export const saveApprovalMatrixCreator = createAction<any>(
  "ADMIN/SAVE_APPROVAL_MATRIX"
);
export const saveApprovalMatrixTypeName = saveApprovalMatrixCreator(null).type;

export const updateproductdiscountCreator = createAction<any>(
  "ADMIN/SAVE_UPDATE_DISCOUNT"
);
export const updateproductdiscountTypeName = updateproductdiscountCreator(null).type;

export const getRequestsForCNCreator = createAction<any>(
  "ADMIN/GET_APPROVAL_CN"
);
export const getRequestsForCNTypeName = getRequestsForCNCreator(null).type;

export const updateRequestsForCNCreator = createAction<any>(
  "ADMIN/UPDATE_APPROVAL_CN"
);
export const updateRequestsForCNTypeName = updateRequestsForCNCreator(null).type;

export const updateRequestStatusCreator = createAction<any>(
  "ADMIN/UPDATE_REQUEST_STATUS"
);
export const updateRequestStatusTypeName = updateRequestStatusCreator(null).type;

export const updateRequestClaimStatusCreator = createAction<any>(
  "ADMIN/UPDATE_REQUEST_CLAIM_STATUS"
);
export const updateRequestClaimStatusTypeName = updateRequestClaimStatusCreator(null).type;

export const getDashboardCountCreator = createAction<any>(
  "ADMIN/DASHBOARD_COUNT"
);
export const getDashboardCountTypeName = getDashboardCountCreator(null).type;

export const statehqlistCreator = createAction<any>(
  "ADMIN/STATE_HQ_LIST"
);
export const statehqlistTypeName = statehqlistCreator(null).type;

export const MRListCreator = createAction<any>(
  "ADMIN/MRList"
);
export const MRListTypeName = MRListCreator(null).type;

export const stockListCreator = createAction<any>(
  "ADMIN/STOCK_LIST"
);
export const stockListTypeName = stockListCreator(null).type;

export const divisionListCreator = createAction<any>(
  "ADMIN/DIVISION_LIST"
);
export const divisionListTypeName = stockListCreator(null).type;

export const getRequestDetailsCreator = createAction<any>(
  "ADMIN/UPDATE_REQUEST_DETAILS"
);
export const getRequestDetailsTypeName = getRequestDetailsCreator(null).type;

export const markFlagCreator = createAction<any>(
  "ADMIN/MARK_FLAG"
);
export const markFlagTypeName = markFlagCreator(null).type;

export const screenLoaderCreator = createAction<any>(
  "ADMIN/SCREEN_LOADER"
);

export const bulkUploadFilesCreator = createAction<any>(
  "ADMIN/BULK_UPLOAD_FILES"
);
export const bulkUploadFilesTypeName = bulkUploadFilesCreator(null).type;

export const editSupplyCreator = createAction<any>(
  "ADMIN/EDIT_SUPPLY"
);
export const editSupplyTypeName = editSupplyCreator(null).type;

export const screenLoaderTypeName = screenLoaderCreator(null).type;


const creditNoteManagementSlice = createSlice({
  name: "CREDIT_NOTE_MANAGEMENT",
  initialState: initialState,
  reducers: {
    updateCreditNote: (state, action) => {
      return {
        ...state,
        creditNote: action.payload,
      };
    },
    updateNewCreditNote: (state, action) => {
      return {
        ...state,
        creditNote: [...state.creditNote, ...action.payload],
      };
    },
    setSummaryDashboard: (state, action) => {
      return {
        ...state,
        summaryDashboard: action.payload,
      };
    },
    setPartyProducts: (state, action) => {
      return {
        ...state,
        partyProducts: action.payload,
      };
    },
    setDiscountList: (state, action) => {
      return {
        ...state,
        discountList: action.payload,
      };
    },
    setApprovalMatrix: (state, action) => {
      return {
        ...state,
        approvalMatrix: action.payload,
      };
    },
    setRequestsForCN: (state, action) => {
      return {
        ...state,
        cnRequests: action.payload,
      };
    },
    setRequestDetails: (state, action) => {
      return {
        ...state,
        requestDetails: action.payload,
      };
    },
    setDashboardCount: (state, action) => {
      return {
        ...state,
        dashboardCount: action.payload,
      };
    },
    setproductList: (state, action) => {
      return {
        ...state,
        productList: action.payload,
      };
    },
    setAdminUserGroup: (state, action) => {
      return {
        ...state,
        adminusergroup: action.payload,
      };
    },
    setstateqList: (state, action) => {
      return {
        ...state,
        statehqlist: action.payload,
      };
    },
    setMRList: (state, action) => {
      return {
        ...state,
        MRList: action.payload,
      };
    },
    setstockList: (state, action) => {
      return {
        ...state,
        stockList: action.payload,
      };
    },
    setdivisionList: (state, action) => {
      return {
        ...state,
        divisionList: action.payload,
      };
    },
    settemplateFile: (state, action) => {
      return {
        ...state,
        templateFile: action.payload,
      };
    },
    setscreenLoader: (state, action) => {
      return {
        ...state,
        screenLoader: action.payload,
      };
    },
  },
});

export const creditNoteManagementActions = creditNoteManagementSlice.actions;
export const creditNoteManagementReducer = creditNoteManagementSlice.reducer;
