import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  ListItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  creditNoteManagemnetSelector,
  updatePartyProductsCreator,
  updateproductdiscountCreator,
  templateFileCreator,
  bulkUploadFilesCreator,
} from '../../redux';
import moment from 'moment';
import { appSelector } from '@app/store/selectors';
import { ToasterService } from '@app/services';
import { ToastType } from '@app/services/toaster';
import './index.css';
import { API_PATH, sizePxToEm } from '@app/common/constants';
import CheckedIcon from '@app/assets/svg/CheckedIcon.svg';
import FileUploadIcon from '@app/assets/svg/file_upload_icon.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_CALL } from '@app/screens/admin-configuration/helper';

const UpdateAllowed = () => {
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);

  const [searchSuggestion, setsearchSuggestion] = useState<any>([]);

  const dispatch = useDispatch();

  const partyProducts = useSelector(
    creditNoteManagemnetSelector.getpartyProductsList(),
    shallowEqual,
  );

  const templateData = useSelector(
    creditNoteManagemnetSelector.gettemplateFile(),
    shallowEqual,
  );

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const [selectedFiles, setSelectedFiles] = useState<any>(null);

  const handleFileChange = (event: any) => {
    console.log('file..', event)
    if (event) {
      setSelectedFiles(null);
      const files = event.target.files?.[0] ?? null;
      console.log(files);
      setSelectedFiles(files);
    } else {
      console.log('not selected')
    }
  };

  useEffect(() => {
    if (partyProducts) {
      setsearchSuggestion(
        partyProducts
          ?.slice(0, 20)
          ?.filter((e: any) => e?.skuName != '')
          ?.slice(0, 7),
      );
    }
  }, [partyProducts]);

  //
  useEffect(() => {
    if (partyProducts?.length) {
      dispatch(
        updatePartyProductsCreator({
          staffPositionId: userInfo?.staffPositionId,
          date: moment().format('DD-MMM-YYYY'),
        }),
      );
    }
  }, [userInfo]);

  // checkbox and search bar selected product
  const [selectedProductList, setselectedProductList] = useState([]);
  const [editedProductList, seteditedProductList] = useState([]);

  const renderHeading = (count: string, label1: string, label2: string) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography sx={{ fontSize: 22.7, fontWeight: '600', color: '#1C1939' }}>
          {count}&nbsp;
        </Typography>
        <Typography sx={{ fontSize: 22.7, fontWeight: '400' }}>
          {label1}&nbsp;
        </Typography>
        <Typography sx={{ fontSize: 22.7, fontWeight: '600', color: '#1C1939' }}>
          {label2}
        </Typography>
      </div>
    );
  };

  const handleCheckBoxChange = (value: any, evt: any) => {
    if (evt?.currentTarget?.checked) {
      let items: any = [...selectedOptions];
      items.push(value);
      setSelectedOptions(items)
    } else {
      const remainItems = selectedOptions?.filter((e: any) => e?.skuId != value?.skuId);
      setSelectedOptions(remainItems);
    }

  }

  const onClick = (data: any) => {
    let allChecked: any = [...selectedProductList];
    allChecked.push(data);
    setselectedProductList(allChecked);
  };

  const onDelete = (data: any) => {
    let allChecked: any = [...selectedProductList];
    setselectedProductList(
      allChecked?.filter((e: any) => e?.skuId != data?.skuId),
    );
  };

  // Fetch discount for selected product
  const handleSubmit = async () => {
    if (editedProductList?.length < 4) {
      let dt: any = [...editedProductList, ...selectedProductList, ...selectedOptions]?.map((e: any) => {
        return {
          Id: e?.Id ?? null,
          ProductId: e?.ProductId ?? e?.skuId,
          DiscountPer: e?.DiscountPer ?? null,
          skuName: e?.skuName,
        }
      })?.filter(
        (v: any, i, a) => a.findIndex((v2: any) => v2?.ProductId === v?.ProductId) === i,
      )?.slice(0, 4);
      // cleat data
      setselectedProductList([]);
      setSelectedOptions([]);
      setsearchData([])
      //api call
      const discountData = await API_CALL(API_PATH.adminConfiguration.discountList, {
        productIds: dt?.map((e: any) => (e?.ProductId)),
        staffPositionId: userInfo?.staffPositionId,
      }, 'POST');
      //Set saveTradeProducts data
      try {
        API_CALL(API_PATH.adminConfiguration.saveTradeProducts, dt?.map((e: any) => (e?.ProductId)), 'POST');
      } catch {
        console.error('saveTradeProducts not set')
      }
      // After API call logic
      if (discountData?.status == 200) {
        const items: any = dt?.map((selectedProduct: any) => {
          const isItem = discountData?.data?.filter(
            (e: any) => e?.productId == selectedProduct?.ProductId,
          )?.[0];
          return {
            ...selectedProduct,
            Id: selectedProduct?.Id ? selectedProduct?.Id : (isItem ? isItem?.id : 0),
            ProductId: selectedProduct?.ProductId,
            DiscountPer: selectedProduct?.DiscountPer ? selectedProduct?.DiscountPer : (isItem ? isItem?.discountPer : null),
          };
        });
        seteditedProductList(items);
      }
      // setselectedProductList([]);
      // setSelectedOptions([]);
      // setsearchData([])
      var getValue: any = document.getElementById('searchProducyBox');
      if (getValue.value) {
        getValue.value = '';
      }
    }
  };

  const onUpdateDiscount = (value: string, itemData: any) => {
    const discountIndex = editedProductList.findIndex(
      (x: any) => x.ProductId == itemData?.ProductId,
    );
    const copyEditedItems: any = [...editedProductList];
    if (discountIndex >= 0) {
      const item = copyEditedItems?.[discountIndex];
      item.DiscountPer = value;
    }
    seteditedProductList(copyEditedItems);
  };

  const deleteEditedItem = (itemData: any) => {
    const items: any = editedProductList?.filter(
      (e: any) => e?.ProductId != itemData?.ProductId,
    );
    seteditedProductList(items);
  };

  const onUpdateDiscountItem = () => {
    const params: any = [];
    editedProductList?.map((e: any) => {
      params.push({
        Id: e?.Id,
        ProductId: e.ProductId,
        DiscountPer: e?.DiscountPer || 0,
      });
    });
    dispatch(updateproductdiscountCreator(params));
    setTimeout(() => {
      seteditedProductList([]);
    }, 500);
  };

  const templateDownloadHandler = () => {
    setfileFlag(true);
    dispatch(
      templateFileCreator({
        staffPositionId: userInfo?.staffPositionId,
        returnInBase64String: true,
      }),
    );
  };

  const [fileFlag, setfileFlag] = useState<any>(false);

  const downloadFileHandler = (data: any) => {
    const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
    const outputFilename = 'template.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
    ToasterService.showToaster(
      'Download request has been processed',
      ToastType.SUCCESS,
    );
    setfileFlag(false);
  };

  useEffect(() => {
    if (templateData && templateData?.length > 0) {
      if (fileFlag) {
        downloadFileHandler(templateData);
      }
    }
  }, [templateData]);

  const OnPickFile = () => {
    document?.getElementById('my_file')?.click();
  };

  const handleSaveFile = () => {
    if (!selectedFiles) {
      ToasterService.showToaster('Please select file', ToastType.WARNING);
      return;
    }
    let data: any = new FormData();
    data.append('Blob', selectedFiles);
    data.append('fileName', selectedFiles.name);
    data.append('StaffPositionId', userInfo?.staffPositionId);
    dispatch(bulkUploadFilesCreator(data));
    setTimeout(() => {
      setSelectedFiles(null);
    }, 1000);
  };

  const [chipsData, setChipsData] = useState<any>(null);

  useEffect(() => {
    const getTrage = async () => {
      const tradeProducts = await API_CALL(API_PATH.adminConfiguration.getTradeProducts, null, 'GET');
      setChipsData(tradeProducts?.data
        ?.slice(0, 20)
        ?.filter((e: any) => e?.skuName != '')
        ?.slice(0, 7))
    }
    getTrage();
  }, [])

  useEffect(() => {
    const searchContainer: any = document.querySelector(
      '.searchContainerStyle',
    );
    const searchInput: any = document.querySelector('.searchInputStyle');

    searchInput.addEventListener('focus', () => {
      searchContainer.classList.add('active');
    });

    searchInput.addEventListener('blur', () => {
      searchContainer.classList.remove('active');
    });
  }, []);

  const [searchData, setsearchData] = useState([]);

  const onSearch = (text: string) => {
    if (!text || text?.length <= 0) {
      setsearchData([]);
    } else {
      // const searchString = text.toLowerCase();
      // const searchResults = partyProducts.filter((item: any) =>
      //   item.skuName.toLowerCase().startsWith(searchString),
      // );
      const getSearchData = (textValue: string) => partyProducts?.filter((item: any) => {
        const itemData = item?.skuName?.toUpperCase();
        const textData = textValue?.toUpperCase();
        return itemData?.indexOf(textData) > -1;
      });
      setsearchData(getSearchData(text)?.slice(0, 5));
    }
  };

  const chipsDataRender = chipsData?.length > 0 ? chipsData : chipsData == null ? [] : searchSuggestion;

  return (
    <div style={{ padding: 20 }}>
      {renderHeading('1.', 'Add', 'Product Details')}
      <div id="autoSearchInput" style={{}}>
        <div className="searchContainerStyle">
          <SearchIcon
            style={{
              width: 16,
              height: 16,
              color: '#322B7C99',
            }}
          />
          <input
            className="searchInputStyle"
            id="searchProducyBox"
            type="text"
            placeholder="Search Product Here..."
            onChange={evt => onSearch(evt.target.value)}
          />
        </div>
        {searchData?.length > 0 && (
          <div className="searchSuggest">
            {searchData?.map((e: any) => {
              return (
                <FormControl style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    style={{ marginTop: 5, marginBottom: 5, color: '#322B7C', fontWeight: 500 }}
                    control={
                      <Checkbox
                        key={e?.skuId + 'checkbox'}
                        style={{ marginRight: 8, }}
                        onChange={(evt: any) => handleCheckBoxChange(e, evt)}
                        defaultChecked={selectedOptions?.filter((e1: any) => e1?.skuId == e?.skuId)?.length > 0 ? true : false}
                      />}
                    label={e.skuName}
                    color='#322B7C'
                  />
                </FormControl>
              );
            })}
          </div>
        )}
      </div>
      <div>
        <ListItem
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            gap: '8px',
            paddingLeft: 0,
          }}>
          {chipsDataRender?.map((data: any) => {
            return selectedProductList.filter(
              (e: any) => e?.skuId == data?.skuId,
            )?.length != 0 ? (
              <Chip
                label={
                  <Typography
                    style={{
                      color: '#C9CCD2',
                      fontSize: 8,
                      alignSelf: 'center',
                      marginRight: 0,
                    }}>
                    {data?.skuName}
                  </Typography>
                }
                style={{
                  borderRadius: 18,
                  background: '#322B7C',
                  border: '1px solid #C9CCD2',
                  padding: 0,
                  height: 24,
                }}
                onDelete={() => onDelete(data)}
                deleteIcon={
                  <img
                    src={CheckedIcon}
                    style={{
                      cursor: 'pointer',
                      width: 8.87,
                      height: 8.87,
                    }}></img>
                }
              />
            ) : (
              <Chip
                label={
                  <Typography
                    style={{
                      color: '#322B7C',
                      fontSize: 8,
                      alignSelf: 'center',
                      marginRight: 0,
                      fontWeight: 400,
                    }}>
                    {data?.skuName}
                  </Typography>
                }
                style={{
                  borderRadius: 18,
                  background: '#FFFFFF',
                  border: '1px solid #C9CCD2',
                  padding: 0,
                  height: 24,
                }}
                onClick={() => onClick(data)}
              />
            );
          })}
        </ListItem>
      </div>
      <div>
        <Button
          variant="contained"
          style={{
            marginTop: 10, backgroundColor:
              (selectedProductList?.length == 0 && selectedOptions?.length == 0) ? "#e4e4e4" :
                '#322b7c'
            , color: "#ffffff"
          }}
          onClick={() => handleSubmit()}
          disabled={selectedProductList?.length == 0 && selectedOptions?.length == 0}
        >
          Add Product
        </Button>
      </div>
      {editedProductList?.length > 0 && (
        <div style={{ width: '100%', margin: '0px 10px 0px 10px' }}>
          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '40%', textAlign: 'left' }}>
              <Typography sx={{ fontSize: 10.7, fontWeight: '600' }}>
                Selected Products
              </Typography>
            </div>
            <div
              style={{
                width: sizePxToEm(200),
                textAlign: 'left',
                marginLeft: 20,
              }}>
              <Typography sx={{ fontSize: 10.7, fontWeight: '600' }}>
                {'Allowed  Discount %'}
              </Typography>
            </div>
          </div>
          {editedProductList?.map((editedItem: any) => {
            return (
              <div
                style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    background: 'rgba(0, 0, 0, 0.06)',
                    width: '40%',
                    alignSelf: 'center',
                    padding: 14,
                  }}>
                  <Typography
                    sx={{ fontSize: 12.7, fontWeight: '600', marginLeft: 2 }}>
                    {editedItem?.skuName}
                  </Typography>
                </div>
                <div style={{ width: sizePxToEm(200), marginLeft: 20 }}>
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Enter Discount"
                    variant="outlined"
                    hiddenLabel
                    type="number"
                    inputProps={{ min: 0, style: { marginBottom: 8 } }}
                    value={editedItem?.DiscountPer || ''}
                    onChange={evt =>
                      onUpdateDiscount(evt?.currentTarget?.value, editedItem)
                    }
                  />
                </div>
                <div
                  style={{
                    alignSelf: 'center',
                    width: '30%',
                    justifyContent: 'center',
                    display: 'flex',
                  }}>
                  <DeleteIcon
                    style={{ color: 'rgb(174, 181, 181)', cursor: 'pointer' }}
                    onClick={() => deleteEditedItem(editedItem)}
                  />
                </div>
              </div>
            );
          })}
          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '40%', justifyContent: 'center' }}>{''}</div>
            <div
              style={{
                width: sizePxToEm(200),
                marginLeft: sizePxToEm(20),
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#322b7c' }}
                onClick={() => onUpdateDiscountItem()}>
                Update
              </Button>
            </div>
          </div>
        </div>
      )}
      <div style={{ display: 'flex', marginTop: 35 }}>
        <Typography sx={{ fontSize: 15, color: '#000000', fontWeight: 400 }}>
          Want to upload in a bulk? Download Excel template &nbsp;
        </Typography>
        <Button
          variant="text"
          //disabled={fileFlag}
          onClick={() => templateDownloadHandler()}
          sx={{
            fontSize: 15,
            fontWeight: '600',
            color: '#322B7C',
            cursor: 'pointer',
            padding: 0,
            minWidth: 0,
          }}>
          {'Click Here'}
        </Button>
      </div>
      <div style={{ marginTop: 14 }}>
        {renderHeading('2.', 'Upload', 'Excel')}
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <div
          onClick={() => OnPickFile()}
          style={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '40%',
            border: '1px #E2E2E2 solid',
            padding: 10,
            borderRadius: 5,
            alignItems: 'center'
          }}>
          <Typography sx={{ fontSize: 12, fontWeight: 400, color: '#322B7C99' }}>
            {selectedFiles ? (
              <span>{selectedFiles?.name}&nbsp;</span>
            ) : (
              'Select File'
            )}
          </Typography>
          <img
            src={FileUploadIcon}
            style={{ cursor: 'pointer', width: 24, height: 24 }}></img>
        </div>
        <input
          multiple={false}
          accept=".xlsx, .xls, .csv"
          type="file"
          id="my_file"
          value={''}
          onChange={(e: any) => handleFileChange(e)}></input>
        <Button
          variant="contained"
          style={{
            marginLeft: 20,
            backgroundColor: selectedFiles ? '#322b7c' : '#322b7c',
          }}
          onClick={() => handleSaveFile()}
        //disabled={selectedFiles?.length == 0}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

export default UpdateAllowed;
