import { API_PATH } from "@app/common/constants";
import { NetworkService, ToasterService } from "@app/services";
import { ToastType } from "@app/services/toaster";
import { takeLatest, select, put, call } from "redux-saga/effects";
import { ConfigurationManagementSelector } from "./seletors";
import {
  activeInactiveExpenseTypeSettingCreatorCreatorTypeName,
  configurationManagementStateActions,
  createExpenseTypeSettingCreatorCreatorTypeName,
  editExpenseTypeSettingCreatorCreatorTypeName,
  fetchExpenseTypeDropdownCreatorTypeName,
  fetchExpenseTypeSettingGridCreatorTypeName,
  fetchExpenseTypeDateGridCreatorTypeName,
  saveExpenseTypeDateGridCreatorCreatorTypeName,
  fetchHqByEdividionDesginationxpenseTypeSettingCreatorTypeName,
  createExpenseTypeSettingValidationCreatorCreatorTypeName,
} from "./slice";
import { filterSelector } from '@app/components/right-sidebar/redux/filterHandlerSelector';
import { filterStateActions } from '@app/components/right-sidebar/redux/filterHandler';
import { formatFilters } from "@app/utils/util";
/**
 * Employee Details Watcher
 */
export function* fetchExpenseTypeDropdownConfigDataWatcher() {
  yield takeLatest(
    fetchExpenseTypeDropdownCreatorTypeName,
    fetchExpenseTypeDropdownWatcherWorker
  );
}
/**
 * Employee Details Worker
 */
export function* fetchExpenseTypeDropdownWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.configurationManagement.dropdown.expenseTypeDropdown}`;
    const expenseTypeDropdown = yield call(
      NetworkService.post, url,
      { hqIds:[0] },
      {}
    );
    yield put(
      configurationManagementStateActions.setExpenseType(
        expenseTypeDropdown?.data
      )
    );
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* fetchExpenseTypeSettingGridDataWatcher() {
  yield takeLatest(
    fetchExpenseTypeSettingGridCreatorTypeName,
    fetchExpenseTypeSettingGridDataWatcherWorker
  );
}

/**
 * Employee Details Worker
 */
export function* fetchExpenseTypeSettingGridDataWatcherWorker(
  action: any
): any {
  try {
    const filter = yield select(
      filterSelector.getFilterOptions()
    );
    
    let request = {
      ...filter,
      pageNumber: filter.pageNumber+1
    }
    yield put(configurationManagementStateActions.setExpenseTypeLoading(true));

      const url = `${API_PATH.configurationManagement.expenseTypeSettingGrid}`;
      const gridData = yield call(NetworkService.post, url, request,{});
      yield put(
        configurationManagementStateActions.setExpenseTypeSetting(
          gridData?.data?.items
        )
      );
      yield put(
        configurationManagementStateActions.setExpenseTypeSettingSnaShot(
          gridData?.data?.items
        )
      );
      yield put(configurationManagementStateActions.setTotalCount(gridData?.data?.totalCount));

      const isconfigurationManagementPage = yield select(
        ConfigurationManagementSelector.getconfigurationManagementPage()
      );

    yield put(configurationManagementStateActions.setExpenseTypeLoading(false));

  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while fetching expense type setting data!",
      ToastType.ERROR
    );
  }
}


/**
 * Employee Details Watcher
 */
 export function* createExpenseTypeSettingValidationWatcher() {
  yield takeLatest(
    createExpenseTypeSettingValidationCreatorCreatorTypeName,
    createExpenseTypeSettingValidationWatcherWorker
  );
}
/**
 * Employee Details Worker
 */
export function* createExpenseTypeSettingValidationWatcherWorker(action: any): any {
  try {
    yield put(
      configurationManagementStateActions.setaddExpenseConfigManagementPopupVisible(
        false
      )
    );
    const url = `${API_PATH.configurationManagement.createExpenseTypeSettingValidation}`;
    const addUser = yield call(NetworkService.post, url,{
      divisionIds:  action.payload.divisionIds,
      designationIds:  action.payload.designationIds,
      expenseTypeId:  action.payload.expenseTypeId,
      hqIds:  action.payload.hqIds,
      isReadOnly:  action.payload.isReadOnly,
      amount:  action.payload.amount,
      isBillRequired:action.payload.isBillRequired,
      startDate: action.payload.startDate ? new Date(action.payload.startDate)?.toLocaleDateString("en-ca"):null,
      // endDate: action.payload.endDate ? new Date(action.payload.endDate)?.toLocaleDateString("en-ca"):null,
      // isActive:  action.payload.isActive
    });
    
      yield put({
        type: fetchExpenseTypeSettingGridCreatorTypeName,
        payload: {},
      });
      if (addUser.data.message) {
        ToasterService.showToaster(addUser.data.message, ToastType.SUCCESS);
      } else {
        ToasterService.showToaster(addUser.data.message, ToastType.ERROR);
      }
    yield put(configurationManagementStateActions.setExpenseTypeSettingCreateValidation(addUser?.data));
  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while Save Expense Type Date!",
      ToastType.ERROR
    );
  }
}


/**
 * Employee Details Watcher
 */
 export function* saveExpenseTypeDateGridWatcher() {
  yield takeLatest(
    saveExpenseTypeDateGridCreatorCreatorTypeName,
    saveExpenseTypeDateGridWatcherWorker
  );
}
/**
 * Employee Details Worker
 */
export function* saveExpenseTypeDateGridWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.configurationManagement.saveExpenseDate}`;
     yield call(NetworkService.post, url, action.payload);
    yield put({
      type: fetchExpenseTypeDateGridCreatorTypeName,
      payload: {},
    });
  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while Save Expense Type Date!",
      ToastType.ERROR
    );
  }
}


/**
 * Employee Details Watcher
 */
export function* createExpenseTypeSettingrWatcher() {
  yield takeLatest(
    createExpenseTypeSettingCreatorCreatorTypeName,
    createExpenseTypeSettingWatcherWorker
  );
}
/**
 * Employee Details Worker
 */
export function* createExpenseTypeSettingWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.configurationManagement.createExpenseTypeSetting}`;
    const addUser = yield call(NetworkService.post, url, action.payload);
    yield put(
      configurationManagementStateActions.setaddExpenseConfigManagementPopupVisible(
        false
      )
    );
    yield put({
      type: fetchExpenseTypeSettingGridCreatorTypeName,
      payload: {},
    });
    if (addUser.data.message) {
      ToasterService.showToaster(addUser.data.message, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(addUser.data.message, ToastType.ERROR);
    }
  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while adding Expense Type Setting!",
      ToastType.ERROR
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchactiveInactiveExpenseTypeSettingWatcher() {
  yield takeLatest(
    activeInactiveExpenseTypeSettingCreatorCreatorTypeName,
    activeInactiveExpenseTypeSettingWatcherWorker
  );
}

/**
 * Employee Details Worker
 */
export function* activeInactiveExpenseTypeSettingWatcherWorker(
  action: any
): any {
  try {
    const isActive = yield select(
      ConfigurationManagementSelector.getIsActiveState()
    );

    const url = `${API_PATH.configurationManagement.expenseTypeSettingActiveInactive}`;
    const disableUser = yield call(NetworkService.post, url, {
      id: action.payload.expenseTypeSettingId,
    });
    if (disableUser.data === true) {
      !isActive &&
        ToasterService.showToaster(
          "ExpenseTypeSetting is Active",
          ToastType.SUCCESS
        );
      isActive &&
        ToasterService.showToaster(
          "ExpenseTypeSetting is In-Active",
          ToastType.SUCCESS
        );

      yield put({
        type: fetchExpenseTypeSettingGridCreatorTypeName,
        payload: {},
      });
    }
  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while Active/InActive Expense Type!",
      ToastType.ERROR
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* editExpenseTypeSettingeWatcher() {
  yield takeLatest(
    editExpenseTypeSettingCreatorCreatorTypeName,
    editExpenseTypeSettingWatcherWorker
  );
}

/**
 * Employee Details Worker
 */
export function* editExpenseTypeSettingWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.configurationManagement.editExpenseTypeSetting}`;
    const editData = yield call(NetworkService.post, url, {
      expenseTypeSettingId: action.payload?.expenseTypeSettingId,
      divisionId: action.payload.divisionId,
      designationId: action.payload.designationId,
      expenseTypeId: action.payload.expenseTypeId,
      hqId: action.payload.hqId,
      amount: action.payload.amount,
      isReadOnly: action.payload.isReadOnly,
      startDate: new Date(action.payload.startDate)?.toLocaleDateString("en-ca"),
      endDate: new Date(action.payload.endDate)?.toLocaleDateString("en-ca"),
      isActive: action.payload.isActive,
    });
    yield put(
      configurationManagementStateActions.setEditExpenseConfigManagementPopupVisible(
        false
      )
    );
    yield put({
      type: fetchExpenseTypeSettingGridCreatorTypeName,
      payload: {},
    });
    if (editData.data) {
      ToasterService.showToaster("Updated Successfully", ToastType.SUCCESS);
    } else {
      ToasterService.showToaster("Something went wrong while updating Expense Type Setting", ToastType.ERROR);
    }
  } catch (error) {
    yield put(
      configurationManagementStateActions.setEditExpenseConfigManagementPopupVisible(
        false
      )
    );
    ToasterService.showToaster(
      "Something went wrong while editing Expense Type Setting!",
      ToastType.ERROR
    );
  }
}


export function* fetchHqByEdividionDesginationxpenseTypeSettingWatcher() {
  yield takeLatest(
    fetchHqByEdividionDesginationxpenseTypeSettingCreatorTypeName,
    fetchHqByEdividionDesginationxpenseTypeSettingWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchHqByEdividionDesginationxpenseTypeSettingWatcherWorker(action: any): any {
  try {
    let ids;
if(action.payload === null){
   ids = {
    divisionIds:[],
    designationIds:[],
  };
}else{
  ids = action.payload
}
    const url = `${API_PATH.configurationManagement.dropdown.hqByDivisionsandDesignation}`;
    const hq = yield call(NetworkService.post, url,ids, {});
      
    yield put(
      configurationManagementStateActions.setExpenseTypeSettingHq(hq?.data),
    );
      
  } catch (error) {}
}


/**
 * Employee Expense Dates Watcher
 */
export function* ExpenseDateWatcher() {
  yield takeLatest(fetchExpenseTypeDateGridCreatorTypeName, ExpenseDateWorker);
}

/**
 * Employee Expense Dates Worker
 */
export function* ExpenseDateWorker(action: any): any {
  try {
    yield put(configurationManagementStateActions.setExpenseDateLoading(true));
    const url = `${API_PATH.configurationManagement.Expense_Date}`;
    

    const editData = yield call(NetworkService.get, url, {}, {});
    yield put(configurationManagementStateActions.setExpenseTypeDate(editData));
    yield put(configurationManagementStateActions.setExpenseDateLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while editing Expense TA Master!",
      ToastType.ERROR
    );
  }
}
