import {useCallback, useEffect, useState} from 'react';
import './modal.css';
import IconButton from '@mui/material/IconButton';
import Modal from 'react-modal';
import {NotificationsNoneOutlined} from '@mui/icons-material';
import axios from 'axios';
import env from '@app/env.json';
import {useSelector} from 'react-redux';
import {getAccessToken} from '@app/helper/login';
import moment from 'moment';

function NotificationModal() {
  const [openModal, setOpenModal] = useState(false);
  const [NotificationData, setNotificationData] = useState([]);

  const dataRedux: any = useSelector(state => state);

  useEffect(() => {
    setInterval(() => {
      if (
        dataRedux?.appState?.userInfo?.userId &&
        dataRedux?.appState?.userInfo?.staffPositionId
      ) {
        getNotificationList();
      }
    }, 10000);
  }, []);

  const getNotificationList =() => {
    void (async () => {
    try {
      const accessToken = await getAccessToken();
      axios
        .get(
          `${env.API_HOST}notification?userId=${dataRedux?.appState?.userInfo?.userId}&staffPositionId=${dataRedux?.appState?.userInfo?.staffPositionId}`,
          {headers: {Authorization: `Bearer ${accessToken}`}},
        )
        .then(res => {
          if (res.status == 200) {
            if (res.data.length > 0) {
              let order = res.data.sort(
                (a: {createdOn: string}, b: {createdOn: string}) =>
                  Date.parse(b.createdOn) - Date.parse(a.createdOn),
              );
              setNotificationData(order);
            }
          }
        })
        .catch(err => {
          return err;
        });
    } catch (err) {}
  })();
  };

   const MarkNotification=(params: any) =>{
    void (async () => {
    try {
      const accessToken = await getAccessToken();
      axios
        .put(`${env.API_HOST}notification/markasread`, [params?.id], {
          headers: {Authorization: `Bearer ${accessToken}`},
        })

        .catch(err => {});
    } catch (error) {}
  })();
  }
  const openModel = useCallback(() => {
    setOpenModal(false);
  }, []);

  const openModels = useCallback(() => {
    setOpenModal(!openModal);
  }, []);

  return (
    <div>
      <div className="bellend">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          data-testid="open-popup"
          onClick={openModels}
          edge="end">
          <NotificationsNoneOutlined color="primary" />
          {NotificationData.filter((e: any) => !e?.isRead).length > 0 && (
            <div
              style={{
                position: 'absolute',
                right: 6.7,
                top: 6.7,
                height: 6.7,
                width: 6.7,
                backgroundColor: 'red',
                borderRadius: 50,
              }}>
              .
            </div>
          )}
        </IconButton>
      </div>

      {openModal && (
        <Modal
          isOpen={true}
          style={{
            content: {
              widows: 'auto',
              height: 'auto',
              border: 'opx',
              right: 0,
              alignSelf: 'flex-end',
              backgroundColor: 'rgba(0,0,0,0)',
            },
            overlay: {zIndex: 2000},
          }}>
          <div className="modalContainer">
            <div className="titleCloseBtn">
              <div className="body">
                <p>Notifications</p>
              </div>
              <button data-testid="close-popup" onClick={openModel}>
                <div className="body">
                  <p>X</p>
                </div>
              </button>
            </div>
            <div className="TodaysDate">
              <p>Today, {moment().format('Do MMMM YYYY')}</p>
            </div>
            <div className="body">
              {NotificationData.length > 0 ? (
                Object.entries(
                  NotificationData.reduce((group: any, product: any) => {
                    const {createdOn}: any = product;
                    group[createdOn] = group[createdOn] ?? [];
                    group[createdOn].push(product);
                    return group;
                  }, {}),
                ).map((data: any) => {
                  return (
                    <div key={data}>
                      <p>{moment(data[0]).format('Do MMMM YYYY')}</p>
                      {data[1].map((NotifiData: any) => {
                        return (
                          <div
                            onClick={() => {
                              MarkNotification(NotifiData);
                            }}
                            className="modalContainerEachRow"
                            key={NotifiData?.message}>
                            <p className="timeRow">
                              {moment(NotifiData?.createdOn).format('LT')}
                            </p>
                            <p>{NotifiData?.message}</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div>
                  <p>No Notification</p>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default NotificationModal;
