import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  ComponentManagementSelector,
  getComponentManagementStateSelector,
} from '../redux/selectors';
import {componentManagementStateActions} from '../redux';
import Stack from '@mui/material/Stack';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {createExpenseTypeCreator} from '../redux/slice';
import {DropdownStyle} from '@app/components/widgets/TextFieldStyle';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';
import {BillLabel, useStyles} from '@app/screens/Styles/style';

export const AddExpenseType = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [status, setStatus] = useState(true);
  const [expenseTypes, setExpenseTypes] = useState('');
  const [expenseTypeNames, setExpenseTypeNames] = useState('');
  const [expenseCategorys, setExpenseCategorys] = useState('');
  const [expenseTypeValidate, setExpenseTypeValidate] = useState(true);
  const [billRequired, setBillRequired] = useState(false);
  const expenseCat = useSelector(
    ComponentManagementSelector.getExpenseCategory(),
  );
  const expenseTypeOption = useSelector(
    ComponentManagementSelector.getExpenseTypeOption(),
  );
  const addExpenseTypePopupVisible = useSelector(
    getComponentManagementStateSelector('addExpenseTypePopupVisible'),
  );

  const onReset = useCallback(() => {
    setExpenseTypeNames('');
    setExpenseCategorys('');
    setExpenseTypes('');
    setExpenseTypeValidate(true);
    setBillRequired(false);
    setStatus(true);
  }, []);
  const handleChange = useCallback(
    (event: {
      target: {checked: boolean | ((prevState: boolean) => boolean)};
    }) => {
      setStatus(event.target.checked);
    },
    [status],
  );
  const handleChangeBillRequired = useCallback(
    (event: {
      target: {checked: boolean | ((prevState: boolean) => boolean)};
    }) => {
      setBillRequired(event.target.checked);
    },
    [billRequired],
  );
  const handleChangeExpenseType = useCallback(
    (event: any) => {
      setExpenseTypes(event.target.value);
    },
    [expenseTypes],
  );
  const handleChangeExpenseCategory = useCallback(
    (event: any) => {
      setExpenseCategorys(event.target.value);
    },
    [expenseCategorys],
  );
  const handleChangeexpenseTypeName = useCallback(
    (event: any) => {
      setExpenseTypeNames(
        event?.target?.value?.replace(/[^a-zA-Z0-9 ]/, '').trimStart(),
      );
    },
    [expenseTypeNames],
  );

  const validationExpenseType = () => {
    if (expenseTypeNames && expenseTypes && expenseCategorys) {
      setExpenseTypeValidate(false);
    } else {
      setExpenseTypeValidate(true);
    }
  };
  const onSaveClick = useCallback(() => {
    const expenseTypeDate = {
      expenseTypeName: expenseTypeNames,
      expenseType: expenseTypes,
      expenseCategory: expenseCategorys,
      startDate: null,
      endDate: null,
      isActive: status,
      isBillRequired: billRequired,
    };

    dispatch(createExpenseTypeCreator(expenseTypeDate));
  }, [expenseTypeNames, expenseTypes, expenseCategorys, status, billRequired]);
  const TriggerEvents = () => {
    handleChange({target: {checked: true}});
    handleChangeBillRequired({target: {checked: true}});
    handleChangeExpenseType({target: {value: 'true'}});
    handleChangeExpenseCategory({target: {value: 'true'}});
    handleChangeexpenseTypeName({target: {value: 'true'}});
    validationExpenseType();
    onSaveClick();
    onReset();
  };

  const TriggerEvent = useCallback(() => {
    return (
      <button
        data-testid="triggeringButton"
        className={'hiddenButton'}
        onClick={TriggerEvents}
      />
    );
  }, []);
  const close = useCallback((reason: string) => {
    if (reason === 'backdropClick') return;
    dispatch(
      componentManagementStateActions.setaddExpenseTypePopupVisible(false),
    );
  }, []);
  const Onclose = useCallback(() => {
    dispatch(
      componentManagementStateActions.setaddExpenseTypePopupVisible(false),
    );
  }, []);
  useEffect(() => {
    validationExpenseType();
  }, [expenseTypeNames, expenseTypes, expenseCategorys]);
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={addExpenseTypePopupVisible}
        onClose={close}>
        <TriggerEvent />
        <DialogTitle>
          <Box className={classes.justifyContentbtflex}>
            <Box>
              <Typography sx={popUpStyle.addcss}>Add</Typography>
              <Typography sx={popUpStyle.headingcss}>Expense Type</Typography>
            </Box>
            <Box>
              <Button
                data-testid="setaddExpenseTypePopupVisible"
                onClick={Onclose}>
                <ClearOutlinedIcon sx={popUpStyle.closeIcon} />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div style={popUpStyle.dialogContent}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>Expense Name</Typography>

              <TextField
                sx={DropdownStyle.textroot}
                id="fullWidth"
                type="text"
                value={expenseTypeNames}
                onChange={handleChangeexpenseTypeName}
                size="small"
              />
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>Expense Type</Typography>
              <Select
                value={expenseTypes}
                displayEmpty
                onChange={handleChangeExpenseType}
                inputProps={{'aria-label': 'Without label'}}
                sx={DropdownStyle.root}
                size="small">
                {expenseTypeOption.length > 0 &&
                  expenseTypeOption.map((element1: any) => (
                    <MenuItem key={element1.id} value={element1.displayName}>
                      {element1.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                Expense Category
              </Typography>
              <Select
                value={expenseCategorys}
                displayEmpty
                onChange={handleChangeExpenseCategory}
                inputProps={{'aria-label': 'Without label'}}
                sx={DropdownStyle.root}
                size="small">
                {expenseCat.length > 0 &&
                  expenseCat.map((element2: any) => (
                    <MenuItem key={element2.id} value={element2.displayName}>
                      {element2.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div>
            <div style={popUpStyle.twocolumnlayout}>
              <FormGroup sx={popUpStyle.marginBottom15}>
                <Typography sx={popUpStyle.labelSizing}>{'Status'}</Typography>
                <Stack  direction="row"
                alignItems="center"
                paddingLeft={1}
                spacing={2}>
                  <BillLabel>In-Active</BillLabel>
                  <AntSwitch
                    checked={status}
                    inputProps={{'aria-label': 'ant design'}}
                    onChange={handleChange}
                  />
                  <BillLabel>Active</BillLabel>
                </Stack>
              </FormGroup>
              <FormGroup sx={popUpStyle.marginBottom15}>
                <Typography sx={popUpStyle.labelSizing}>
                  {'Bill Required'}
                </Typography>
                <Stack  direction="row"
                alignItems="center"
                paddingLeft={1}
                spacing={2}>
                  <BillLabel>No</BillLabel>
                  <AntSwitch
                    checked={billRequired}
                    onChange={handleChangeBillRequired}
                    inputProps={{'aria-label': 'ant design'}}
                  />
                  <BillLabel>Yes</BillLabel>
                </Stack>
              </FormGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Box className={classes.justifyContentcenterflex}>
            <Button
              variant="contained"
              sx={popUpStyle.resetButton}
              onClick={onReset}>
              Reset
            </Button>

            <Button
              variant="contained"
              sx={popUpStyle.saveButton}
              onClick={onSaveClick}
              disabled={expenseTypeValidate}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
