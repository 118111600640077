import {useCallback, useEffect, useState} from 'react';
import {Search} from '@mui/icons-material';
import { debounce } from 'lodash';
import {
  Autocomplete,
  FormControl,  
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {useSelector, useDispatch,shallowEqual} from 'react-redux';
import { AntSwitch } from '@app/components/widgets/AntSwitch/AntSwitch';
import { expenseDAMasterStateSelector } from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import { expenseDAMasterStateActions } from '@app/screens/master-data-management/pages/expense-da-master/redux';
import { fetchExpenseDAMasterGridCreator, fetchExpenseDAMasterInAutoSuggestCreator } from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import { searchBarStyle } from '@app/components/widgets/searchBarCss/searchBarCss';
import { filterSelector, filterStateActions } from '@app/components/right-sidebar/redux';

export const SearchBarExpenseDAMaster = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const isActiveStateDA = useSelector(
    expenseDAMasterStateSelector.getIsActiveState(),
  );
  
  const ExpenseDAMasterInAutoSuggestFilter = useSelector(
    expenseDAMasterStateSelector.getExpensDAMasterInAutoSuggestFilter(),
  );

  const request = useSelector(
    filterSelector.getFilterOptions()
  );
  useEffect(() => {
    dispatch(filterStateActions.setFilterOptions({
      ...request,
      searchText:ExpenseDAMasterInAutoSuggestFilter?.divisionDesignation ?? ''
    }))
    dispatch(fetchExpenseDAMasterGridCreator(null));
  }, [ExpenseDAMasterInAutoSuggestFilter]);

  useEffect(() => {
    return () => {
      dispatch(expenseDAMasterStateActions.setExpenseDAMasterInAutoSuggestFilter({}))
    }
  }, [dispatch])
  
  const autosuggestDataDAMaster = useSelector(
    expenseDAMasterStateSelector.getExpenseDAMasterInAutoSuggest(),
  );
  const resetNavbarVersionDAMaster = useSelector(
    expenseDAMasterStateSelector.getResetNavbarVersion(),
    shallowEqual,
  );

  const debouncedSearchHandler = debounce(
    (event: any) => {
      dispatch(
        fetchExpenseDAMasterInAutoSuggestCreator(
          event.target.value,
        ),
      );
    }, 400
  );
  const onChangeVal = useCallback(function (_event: any, value: any) {
    dispatch(filterStateActions.setFilterAppliedDAMaster(false));
    if (!value) {
      dispatch(fetchExpenseDAMasterGridCreator(null));
    }
    dispatch(expenseDAMasterStateActions.setSearchText(value));
    dispatch(
      expenseDAMasterStateActions.setExpenseDAMasterInAutoSuggestFilter(
        value
      )
    );
  },[])
  const onOpen = useCallback(() => setOpen(true),[]);
  useEffect(() => {
    dispatch(filterStateActions.setFilterOptions({
      ...request,
      isActive:isActiveStateDA
    }))
    dispatch(fetchExpenseDAMasterGridCreator(null));
   
  }, [isActiveStateDA])

  const DAactiveInactive = useCallback((event: React.ChangeEvent<HTMLInputElement>) =>{
    dispatch(
      expenseDAMasterStateActions.setIsActiveState(event.target.checked),
    );
  },[isActiveStateDA]);
const onClose = useCallback(() =>{setOpen(false);
  dispatch(expenseDAMasterStateActions.setExpenseDAMasterInAutoSuggest([]));},[]);
   const getOptionLabelHandler = useCallback(function (option: any) {
        return option.divisionDesignation
    },[])
    const renderInput = useCallback(function (params2: any) {
      return(
        <TextField
          onChange={debouncedSearchHandler}
          {...params2}
          sx={searchBarStyle.textField}
          placeholder={'Search here...'}
          InputProps={{
            ...params2.InputProps,
            endAdornment: (
              <>
              </>
            ),
            startAdornment: (
              <InputAdornment
                position="start"
                sx={searchBarStyle.padding5}
              >
                <Search />
              </InputAdornment>
            ),
           
          }}
        />
      )
    },[])
    useEffect(() => {
      return()=>{
        dispatch(filterStateActions.setFilterOptions({
          isActive: true,
          searchText: "",
          pageNumber: 0,
          pageLimit:100,
          divisionIds: [],
          designationIds: [],
          hqIds: []
        }))
      }
    },[])
  return ( 
    <div>
      <Toolbar sx={searchBarStyle.toolbar}>
        <FormControl sx={searchBarStyle.width60}>
          <Autocomplete
          key={
           resetNavbarVersionDAMaster
          }
            open={open}
            onChange={onChangeVal}
            onOpen={onOpen}
            onClose={onClose}
           
            getOptionLabel={getOptionLabelHandler}
            options={
              autosuggestDataDAMaster && autosuggestDataDAMaster.length > 0
                ? autosuggestDataDAMaster
                : []
            }
           
            renderInput={renderInput}
          />
        </FormControl>

<FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              color={'#322b7c'}
              fontWeight={'800'}
              fontFamily={'Poppins'}
              fontSize={'normal'}
            >
              In-Active
            </Typography>
            <AntSwitch
              onChange={DAactiveInactive}
              checked={isActiveStateDA}
              inputProps={{'aria-label': 'ant design'}}
            />
            <Typography
              color={'#322b7c'}
              fontWeight={'800'}
              fontFamily={'Poppins'}
              fontStyle={'normal'}
            >
              Active
            </Typography>
          </Stack>
        </FormGroup> 
      </Toolbar>
    </div>
  );
};
