import * as React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface DatePickerProps {
  value: Date | null;
  onChange: Function;
  placeholder: string;
  disablePast: boolean;
  onError: Function;
  isHeaderStyle: Boolean
}

function DatePickerComponent(props: DatePickerProps) {
  const [open, setOpen] = React.useState(false);
  const date = new Date();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        open={open}
        onError={() => props.onError()}
        value={props.value}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disablePast={props.disablePast}
        label={props.placeholder}
        inputFormat="dd-MM-yyyy"
        maxDate={date.getTime()}
        onChange={(newValue: any) => {
          props.onChange(newValue);
        }}
        renderInput={params => {
          delete params?.inputProps?.placeholder
          if (props?.isHeaderStyle) {
            delete params?.label
          }
          return (<div style={{ position: "relative", display: "inline-block" }} >
            <TextField
              {...params}
              onClick={() => setOpen(true)}
              variant="outlined"
              placeholder={props.placeholder || ''}
              hiddenLabel={true}
              style={{ width: '100%', borderRadius: 10, backgroundColor: 'white', textOverflow: 'ellipsis' }}
              sx={{
                svg: {
                  color: '#322b7c',
                },
                label: {
                  //'::placeholder': '#3a1c1939',
                  marginTop: -0.5,
                  paddingLeft: 0.5,
                  fontSize: 12.7,
                  color: '#1C1939',
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  opacity: 1,
                },
              }}
            />
            {props.value &&
              <IconButton style={{ position: "absolute", margin: "auto", right: "1.9rem", color: '#322b7c' }} onClick={() => props.onChange(null)}>
                <CloseIcon />
              </IconButton>
            }
          </div>)
        }}
      />
    </LocalizationProvider>
  );
}

export default React.memo(DatePickerComponent);
