import { routeStateActions } from '@app/router/redux';
import { navbarComponentName } from '@app/router/redux/routeHandler';

import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  adminActions, adminSelector,
} from '../../redux';
import ApproveRequest from './component/ApproveRequest';
import Summary from './component/Summary';
import './creditStyle.css';
import UpdateAllowed from './component/UpdateAllowed';
import ManageUserRole from './component/ManageUserRole';
import { MRListCreator, adminusergroupCreator, creditNoteManagemnetSelector, divisionListCreator, statehqlistCreator, stockListCreator, updatePartyProductsCreator } from './redux';
import { appSelector } from '@app/store/selectors';
import moment from 'moment';

const CreditNoteManagement = () => {
  const dispatch = useDispatch();
  const tabNumber = useSelector(adminSelector.getNavTabNumber());
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const screenLoader = useSelector(creditNoteManagemnetSelector.getscreenLoader(), shallowEqual);

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.adminConfiguration,
      ),
    );
    dispatch(
      adminActions.updateNavData({
        title: 'Credit Note Management',
        status: { show: true, value: true },
      }),
    );
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
  }, []);


  useEffect(() => {
    dispatch(adminusergroupCreator({
      staffPositionId: userInfo?.staffPositionId
    }))
    //filter
    dispatch(
      statehqlistCreator({}));
    dispatch(
      MRListCreator({
        staffPositionId: userInfo?.staffPositionId
      }));
    dispatch(
      stockListCreator({
        staffPositionId: userInfo?.staffPositionId
      }));
    dispatch(
      divisionListCreator({}));
    //product select
    dispatch(
      updatePartyProductsCreator({
        staffPositionId: userInfo?.staffPositionId,
        date: moment().format('DD-MMM-YYYY'),
      }),
    );
  }, [userInfo?.staffPositionId])

  return (
    <>
      {tabNumber == 0 ? <ApproveRequest /> :
        tabNumber == 1 ? <div><Summary /></div> :
          tabNumber == 2 ? <div><UpdateAllowed /></div> :
            tabNumber == 3 ? <div><ManageUserRole /></div> : null}
      {screenLoader == true && <div className="dispaly_loader"></div>}
    </>
  );
};

export default CreditNoteManagement;
