import { createAction, createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  nav: Record<string, any>;
}

const initialState: initialStateProps = {
  nav: {
    title: null,
    tabNumber: 0,
    action: {
      title: null,
      type: null,
    },
  }
};

export const updateAdminNavDataCreator = createAction<any>(
  "ADMIN_CONFIGURATION/UPDATE_ADMIN_NAV_DATA"
);
export const updateAdminNavDataCreatorTypeName =
  updateAdminNavDataCreator(null).type;

const adminSlice = createSlice({
  name: "ADMIN_CONFIGURATION",
  initialState: initialState,
  reducers: {
    updateNavData: (state, action) => {
      return {
        ...state,
        nav: action.payload,
      };
    },
    updateLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const adminActions = adminSlice.actions;
export const adminReducer = adminSlice.reducer;
