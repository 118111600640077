
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles.css';
import { Button } from '@mui/material';
import { CN_Approve, CN_REJECT } from '@app/common/constants';
import { shallowEqual, useSelector } from 'react-redux';
import { creditNoteManagemnetSelector } from '../redux';
import { debounce } from 'lodash';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CustomGrid = (props: { columnDefs: any, rowData: any, isHeader?: boolean, rejectHandler?: any, approvedHandler?: any, userType?: string }) => {
    const { columnDefs = [], rowData = [], isHeader = false, userType = '' } = props;
    const containerStyle = useMemo(() => ({ width: '100%', height: '80%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridRef = useRef<any>();
    const defaultColDef = useMemo(() => {
        return {
            resizable: false,
            suppressMovable: true
        };
    }, [])
    const [isShow, setIsShow] = useState(false)
    const [showBar, setShowBar] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setIsShow(true)
        }, 1000)
    }, [])

    function isRowSelectable(params: any) {
        // Check your condition here
        if (params?.data?.isClaimCompleted) {
            return false; // Disable selection
        } else {
            return true; // Enable selection
        }
    }

    const gridOptions = {
        suppressNoRowsOverlay: true
        // getRowStyle: (params: any) => {
        //     if (isHeader && params.node.data.isClaimCompleted === true) {
        //         return { background: 'red' };
        //     }
        // },
        // other grid options ...
        //isRowSelectable: isRowSelectable
    }


    //fetch loader 
    const screenLoader = useSelector(creditNoteManagemnetSelector.getscreenLoader(), shallowEqual);

    const onselectRowClick = (isDeselect?: boolean) => {
        if (gridRef.current) {
            if (isDeselect) {
                gridRef.current.api.deselectAll();
            } else {
                gridRef.current.api.selectAll();
            }
        }
    }

    const onClickAll = (isApproved: boolean) => {
        const selectedRow = gridRef?.current?.api?.getSelectedRows() || [];
        const electedIds = selectedRow?.map((e: any) => e?.requestId);
        console.log('selectedRow--', selectedRow,)
        if (isApproved) {
            props?.approvedHandler(electedIds, selectedRow);
        } else {
            props?.rejectHandler(electedIds, selectedRow);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            //to hide top scroll on FC grid
            if (showBar) {
                setShowBar(false)
            }
        }, 3000);
    }, [])

    const onFirstDataRendered = (params: any) => {
        setTimeout(() => {
            //to hide top scroll on FC grid
            setShowBar(false)
        }, 1000);
        setTimeout(() => {
            if (userType == "CN") {
                gridRef.current.columnApi.autoSizeAllColumns();
            } else {
                gridRef.current?.api.sizeColumnsToFit()
            }
        }, 200)
        onPaginationChanged()
        // const nodesToSelect: any = [];
        // params.api.forEachNode((node: any) => {
        //     if (node.data && node.data.isClaimCompleted === true) {
        //         nodesToSelect.push(node);
        //     }
        // });
        // params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
    };

    const [rowSelectionCount, setRowSelectionCount] = useState(0);
    const [istextSelectable, setistextSelectable] = useState(true);

    const onSelectionChanged = debounce((params: any) => {
        setRowSelectionCount(params?.api.getSelectedRows()?.length)
    }, 100)


    const [totalPage, settotalPage] = useState(0);
    const [currentPage, setcurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const onPageSizeChanged = useCallback((pageSize: any) => {
        const value: any = document?.getElementById('page-size');
        const val1 = value?.value;
        setPageSize(val1)
        if (gridRef?.current?.api) {
            gridRef.current.api.paginationSetPageSize(Number(val1));
        }
    }, []);

    let isDragging = false;
    let initialClickX: any;

    const onGreadReady = () => {
        try {
            const elementView: any = document.getElementsByClassName("ag-body-horizontal-scroll-viewport")?.[0];
            document.getElementsByClassName("ag-center-cols-container")?.[0].addEventListener('mousedown', (e: any) => {
                isDragging = true;
                initialClickX = e.clientX;
            });

            document.getElementsByClassName("ag-center-cols-container")?.[0].addEventListener('mousemove', (e: any) => {
                if (isDragging) {
                    const deltaX = (e.clientX - initialClickX);
                    elementView.scrollLeft = deltaX + elementView.scrollLeft;
                    setistextSelectable(false)
                }
            });

            document.getElementsByClassName("ag-center-cols-container")?.[0].addEventListener('mouseup', () => {
                isDragging = false;
                setistextSelectable(true)
            });

            document.getElementsByClassName("ag-center-cols-container")?.[0].addEventListener('mouseleave', () => {
                isDragging = false;
                setTimeout(() => {
                    setistextSelectable(true)
                }, 1000)
            });
        }
        catch {
            console.log('---ag-center-cols-container class not found---')
        }
    }

    const onPaginationChanged = useCallback(() => {
        console.log('onPaginationPageLoaded');
        if (gridRef?.current?.api) {
            setcurrentPage(
                gridRef.current.api.paginationGetCurrentPage() + 1
            );
            settotalPage(gridRef.current.api.paginationGetTotalPages())
        }
    }, [gridRef?.current?.api]);

    const onBtNext = useCallback(() => {
        gridRef.current.api.paginationGoToNextPage();
    }, []);

    const onBtPrevious = useCallback(() => {
        gridRef.current.api.paginationGoToPreviousPage();
    }, []);

    const getPazeSize = () => {
        const totalRowCount = rowData.length;
        const lowerRange = (currentPage - 1) * pageSize + 1;
        const upperRange = Math.min(currentPage * pageSize, totalRowCount);
        return <span>
            {`Results: ${lowerRange < 0 ? 0 : lowerRange}-${upperRange} of ${totalRowCount}`}
        </span>
    }

    const isLastPage = currentPage === totalPage ? true : false;
    const isFirstPage = (currentPage === 0 || currentPage === 1) ? true : false

    return (
        <div style={containerStyle}>
            {isHeader && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 20 }}>
                <div>
                    <Button onClick={() => onselectRowClick()} style={{ minWidth: 0, fontSize: 16, fontWeight: 600, }}>
                        Select All
                    </Button>
                    <Button style={{ minWidth: 0, fontSize: 16, color: rowSelectionCount > 0 ? '#322b7c' : '#322B7C4D', fontWeight: 600 }} onClick={() => onselectRowClick(true)}>Deselect All</Button>
                </div>
                <div>
                    {rowSelectionCount > 0 && <span style={{ marginRight: 20, fontSize: 10.7 }}> <span style={{ fontWeight: 600 }}>{rowSelectionCount} {`${rowSelectionCount == 1 ? 'Item' : 'Items'}`}</span>{' Selected'}</span>}
                    <Button variant="outlined" style={{ marginRight: 25, height: 42.7 }} onClick={() => onClickAll(false)}>{CN_REJECT}</Button>
                    <Button variant="contained" style={{ height: 42.7 }} onClick={() => onClickAll(true)}>{CN_Approve}</Button>
                </div>
            </div>}
            <div className="grid-wrapper aggrid_custom_scrollbar" style={{ width: '100%', height: '100%' }}>
                {userType != "CN" && showBar && <div style={{ height: 10, background: 'white', zIndex: 10, position: 'absolute', width: 980 }} />}
                <div style={gridStyle} className='ag-theme-alpine hide-scrollbar'>
                    {!screenLoader && isShow && <AgGridReact
                        rowData={screenLoader ? null : rowData}
                        columnDefs={columnDefs}
                        rowSelection={'multiple'}
                        suppressRowClickSelection
                        onGridReady={(params) => {
                            gridRef.current = params;
                            onGreadReady()
                            //return params.columnApi.autoSizeAllColumns();
                        }}
                        onFirstDataRendered={(params) => onFirstDataRendered(params)}
                        defaultColDef={defaultColDef}
                        enableCellTextSelection={istextSelectable ? true : false}
                        onSelectionChanged={(params) => onSelectionChanged(params)}
                        gridOptions={gridOptions}
                        pagination={true}
                        paginationPageSize={10}
                        className={`hide-scrollbar`}
                        suppressPaginationPanel={true}
                        //suppressHorizontalScroll={true}
                        onPaginationChanged={() => onPaginationChanged()}
                    />}
                </div>
            </div>
            {!screenLoader &&
                <div hidden={!isShow}>
                    <div className='custom_pagination' style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between', fontSize: 10.7 }}>
                        <div style={{ fontSize: 10.7, color: '#747379', fontWeight: 600 }}>
                            {getPazeSize()}
                        </div>
                        <div style={{ color: '#322B7C' }}>
                            <ArrowBackIosNewIcon style={{ fontSize: 18.7, cursor: isFirstPage ? 'auto' : 'pointer', opacity: isFirstPage ? 0.3 : 1 }}
                                onClick={() => {
                                    !isFirstPage && onBtPrevious()
                                }}
                            />&nbsp;
                            <ArrowForwardIosIcon style={{ fontSize: 18.7, cursor: isLastPage ? 'auto' : 'pointer', opacity: isLastPage ? 0.3 : 1 }}
                                onClick={() => {
                                    !isLastPage && onBtNext()
                                }}
                            />
                        </div>
                        <div className="PaseSizeDelection" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <div className='pageSize' style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 10,
                                padding: 10, height: 20, width: 58.2, fontWeight: 700, fontSize: 10.7, color: '#FFFFFF', background: '#322B7C', borderRadius: 10
                            }}>
                                <span style={{}}>{currentPage}&nbsp;</span>
                                <span>{`of ${totalPage}`}</span>
                            </div>
                            <div>
                                <select onChange={(evt: any) => onPageSizeChanged(evt?.target?.value)} id="page-size" style={{
                                    border: '1px #322B7C solid', borderRadius: 4, width: 46, color: '#322B7C', fontWeight: 700
                                }}
                                    value={pageSize}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>}
            <div style={{ height: 20 }} />
        </div >
    );
};

export default CustomGrid;