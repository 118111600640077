import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button, Card, Checkbox, Chip, FormControlLabel, Grid, Input, ListItem, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CustomTable from "../../ui-component/CustomTable";
import { Item } from "@app/screens/Styles/style";
import './index.css';
import { creditNoteManagemnetSelector, fileDownloadCreator, getRequestDetailsCreator, updateRequestClaimStatusCreator, updateRequestStatusCreator } from "../../redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@app/assets/svg/DownloadIcon.svg';
import { fileDownload } from "@app/screens/admin-configuration/helper";
import { ToasterService } from "@app/services";
import { ToastType } from "@app/services/toaster";

const styles = {
    headerTitle: {
        fontSize: 22.7, fontWeight: '500', color: '#1C1939'
    },
    subHeading: {
        fontSize: 18.7, fontWeight: '600', color: '#1C1939'
    }
}


const CheckApplication = (props: { selectedData: any, onCloseHandler?: any, isViewSupplyBills?: boolean }) => {
    const dispatch = useDispatch();
    const requestDetailsData = useSelector(creditNoteManagemnetSelector.getrequestDetails(), shallowEqual);

    const [applicationData, setApplicationData] = useState<any>(null);
    const screenLoader = useSelector(creditNoteManagemnetSelector.getscreenLoader(), shallowEqual);

    const remarkinputRef = useRef<HTMLInputElement | null>(null);


    useEffect(() => {
        setApplicationData(requestDetailsData)
    }, [requestDetailsData])

    useEffect(() => {
        if (!props?.isViewSupplyBills) {
            dispatch(
                getRequestDetailsCreator(props?.selectedData?.requestId))
        }
        //getRequestDetailsCreator(830))
    }, [props?.selectedData])

    const renderHeading = (title: string, styles: any = {}) => {
        return <Typography sx={{ ...styles }}>
            {title}
        </Typography>
    }

    const renderCardLabel = (label: string, value: string) => {
        return (
            <div >
                <Typography className="inputLabel">
                    {label}
                </Typography>
                <Typography className="inputValue">
                    {value}
                </Typography>
            </div>
        )
    }

    useEffect(() => {
        approvalDataHandler(applicationData)
        reuestDetailHanler(applicationData?.requestDetail)
        productDetailHandler(applicationData?.productReviews)
        remarkHandler(applicationData?.priorApprovalRemarks)
        supplyBillsHandler(applicationData?.supplyBills);
    }, [applicationData]);

    const [approvalRowData, setapprovalRowData] = useState<any>([]);
    const [approvalColData, setapprovalColData] = useState<any>([]);

    const approvalDataHandler = (dataProps: any) => {
        let colData: any = [{ name: '', key: 'approvalType' }];
        dataProps?.claimApprovalStatuses?.map((e: any) => {
            colData.push({
                ...e,
                name: e?.statusBy == 'PMT' ? 'PMT Head' : e?.statusBy,
                key: e?.statusBy,
                type: 'showActive'
            })
        })
        let rowData: any = []
        setapprovalColData(colData)
        let claimApprovalStatusesRow: any = {
        }
        colData?.map((e: any) => {
            let status = dataProps?.claimApprovalStatuses?.filter((e1: any) => e1?.statusBy == e?.key)?.[0]?.status;
            claimApprovalStatusesRow[e?.key] = status == 'Pending' ? 'Pending' : status == 'Approved' ? 'Approved' : status == 'Rejected' ? 'Rejected' : status
        })
        claimApprovalStatusesRow['approvalType'] = 'Claim Approval'
        let priorApprovalStatusesRpw: any = {
            approvalType: 'Claim Approval'
        }
        colData?.map((e: any) => {
            let status = dataProps?.priorApprovalStatuses?.filter((e1: any) => e1?.statusBy == e?.key)?.[0]?.status;
            priorApprovalStatusesRpw[e?.key] = status == 'Pending' ? 'Pending' : status == 'Approved' ? 'Approved' : status == 'Rejected' ? 'Rejected' : status
        })
        priorApprovalStatusesRpw['approvalType'] = 'Prior Approval'
        rowData.push(priorApprovalStatusesRpw)
        rowData.push(claimApprovalStatusesRow)
        setapprovalRowData(rowData);
    }

    const [requestDetailData, setetrequestDetailData] = useState<any>();

    const downloadPrescription = (fileName: any) => {
        dispatch(
            fileDownloadCreator({
                fileName: fileName,
                isType: 'prescription'
            },))
    }

    const reuestDetailHanler = (dataProps: any) => {
        if (!dataProps) return;
        const requestDetails = [
            { label: 'Created By', key: 'createdByName' },
            { label: 'C&F.', key: 'cfName' },
            { label: 'Doctor', key: 'doctorName' },
            { label: 'Doctor’s Type', key: 'doctorTypeName' },
            { label: 'Doctor’s Potential (AVG 3 Months)', key: 'doctorPotentialAvg' },
            { label: 'Claim No.', key: 'requestNumber' },
            { label: 'Stockist', key: 'stockistName' },
            {
                label: 'Speciality', key: 'specialisations', renderCell: (params: any) => {
                    return params?.specialisations?.map(
                        (item: any, index: any) => {
                            return <span>{item?.name} </span>
                        },
                    )
                }
            },
            { label: 'Chemists attached to Doctor', key: 'chemistName' },
            {
                label: 'Dr Support', key: 'doctorSupport', renderCell: (params: any) => {
                    return `${params?.doctorSupport === null
                        ? ''
                        : `${params?.doctorSupport}`
                        }`
                }
            },
            {
                label: 'Date', key: 'requestDate', renderCell: (params: any) => {
                    return moment(params?.requestDate).format(
                        'DD MMMM YYYY',
                    )
                }
            },
            { label: 'Request raised this quarter by stockist', key: 'numberOfRequestRaisedByStockistQuarterly' },
            { label: 'Region', key: 'address' },
            {
                label: 'Doctor’s Prescription', key: 'prescriptionFileName', renderCell: (params: any) => {
                    return <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => downloadPrescription(params?.prescriptionFilePath)} href="#">{params?.prescriptionFileName ? `${params?.prescriptionFileName.split('.')[0]?.slice(0, 25)}.${params?.prescriptionFileName.split('.')[1]?.slice(0, 10)}` : ''}</a>
                }
            },
            {
                label: 'Request raised this quarter', key: 'numberOfRequestRaisedByQuarterly', renderCell: (params: any) => {
                    return `${params?.numberOfRequestRaisedByQuarterly || '-'} ${params?.requestStatus || ''}`
                }
            },
            {
                label: 'Bonus Amount Raised This Quarter (In Rs.)', key: 'numberOfRequestRaisedByQuarterly', renderCell: (params: any) => {
                    return params?.bonusAmountRaisedQuarterly || '-'
                }
            }
        ];


        let prepareData: any = [];
        requestDetails?.map(e => {
            prepareData.push({
                label: String(e?.label).toUpperCase(),
                value: e?.renderCell ? e?.renderCell(dataProps) : dataProps[e?.key]
            })
        })
        setetrequestDetailData(prepareData);
    }

    const [productDetailCol, setproductDetailCol] = useState<any>();
    const [productDetailRow, setproductDetailRow] = useState<any>();

    const productDetailHandler = (dataProps: any) => {
        const productColumn = [
            { name: 'Selected Product', key: 'name' },
            { name: 'Required Extra', key: 'additionalRequirement' },
            { name: 'Allowed Discount %', key: 'allowedDiscountPer' },
            { name: 'Monthly Consumption', key: 'monthlyConsumption' },
            { name: 'Bonus', key: 'bonusSchemePercentage' },
            { name: 'Company Name', key: 'companyName' },
        ];
        setproductDetailCol(productColumn);
        if (!dataProps) return
        const productData = dataProps?.map((e: any) => {
            const className = e?.additionalRequirement > e?.allowedDiscountPer ? 'additionalDiscountClass' : null;
            if (className) {
                return {
                    ...e,
                    rowAdditionalClass: className
                }
            } else {
                return e;
            }
        })
        setproductDetailRow(productData)
    }

    const [remarkCol, setremarkCol] = useState<any>();
    const [remarkRow, setremarkRow] = useState<any>();
    const [remarkText, setremarkText] = useState('');

    const remarkHandler = (dataProps: any) => {
        const remarkColumn = [
            { name: '', key: 'remarkBy', className: 'remarkByHeader', width: '20%' },
            { name: '', key: 'remark' }
        ];
        const remarkByAddedText = dataProps?.map((e: any) => {
            return {
                ...e,
                remarkBy: `${e?.remarkBy ? (e?.remarkBy + '\’S REMARKS') : ''}`
            }
        });
        setremarkCol(remarkColumn)
        setremarkRow(remarkByAddedText)
    }

    const [supplyBillsCol, setsupplyBillsCol] = useState<any>();
    const [supplyBillsRow, setsupplyBillsRow] = useState<any>();

    const supplyBillsHandler = (dataProps: any) => {
        const supplyBillsColumn = [
            { name: 'Bill No.', key: 'billNumber', width: '15%' },
            { name: 'Bill Date', key: 'billDate', type: 'date', width: '15%' },
            { name: 'Bill Copy', key: 'billFileName', type: 'link', linkAddress: 'billFilePath' },
        ];
        setsupplyBillsCol(
            supplyBillsColumn);
        if (!dataProps) return
        setsupplyBillsRow(dataProps)
    }

    const requestClickHandler = (isApprove: boolean) => {
        if ((String(remarkText)?.trim()?.length <= 0) && remarkinputRef?.current) {
            remarkinputRef.current.focus();
            return 0;
        }
        const requestType: any = 'appliation';
        if (requestType == 'appliation') {
            dispatch(
                updateRequestStatusCreator({
                    "role": 2,
                    "remarks": remarkText || '',
                    "statusDetails": {
                        "requestId": props?.selectedData?.requestId,
                        "currentStatusId": isApprove ? 1 : 3,
                        "futureStatusId": isApprove ? 2 : 3,
                    }
                }))
            props?.onCloseHandler && props?.onCloseHandler()
        }
        else {
            if (requestType == 'claim') {
                dispatch(
                    updateRequestClaimStatusCreator({
                        "id": props?.selectedData?.requestId,
                        "requestClaimId": 2,
                        "currentStatusId": isApprove ? 1 : 3,
                        "futureStatusId": 1,
                        "remarks": remarkText || ''
                    }))
                props?.onCloseHandler && props?.onCloseHandler()
            }
        }
    }

    const downloadPage = () => {
        try {
            const input: any = document.getElementById("divToPrint");
            const pdfWidth = 180; // A4 size width
            // Calculate the aspect ratio of the content
            const aspectRatio = input.clientWidth / input.clientHeight;
            html2canvas(input).then(canvas => {
                const imgData = canvas.toDataURL("image/png");
                // Calculate the height based on the desired width and aspect ratio
                const pdfHeight = pdfWidth / aspectRatio;
                const pdf = new jsPDF("p", "mm", "a4");
                pdf.addImage(imgData, "PNG", 10, 5, pdfWidth, 200);
                pdf.save("application.pdf");
                setTimeout(() => {
                    ToasterService.showToaster(
                        'Download request has been processed',
                        ToastType.SUCCESS,
                    );
                }, 500)
            });
        } catch {
            ToasterService.showToaster(
                'Download request has been failed',
                ToastType.ERROR,
            );
        }
    }


    const isActioned: any = applicationData?.requestDetail?.isActionedByCurrentUser || applicationData?.requestDetail?.isApplicationApproved;
    const isViewBillsPopup = props?.isViewSupplyBills;

    const renderProductDetails = (productCol: any, productData: any) => {
        const subData = ['competitorProduct', 'additionalRequirement', '', 'monthlyConsumption', 'bonusSchemeQty', 'companyName']
        return <div>
            <table className="productTable" id='productTabId'>
                <tr>
                    {productCol?.map((e: any) =>
                        <th className="headerCellItem">{e?.name}</th>
                    )}
                </tr>
                {productData?.map((item: any) => {
                    const rowColor = (item?.additionalRequirement > item?.allowedDiscountPer) ? '#DDE7EB' : '';
                    return <tr className="rowCellItem productRowStyle" style={{ background: rowColor }}>
                        {productCol?.map((e: any, idKey: any) =>
                            <td className="productCellStyle ">
                                <div>{(item?.[e?.key] != null && (item?.[e?.key] >= 0 || item?.[e?.key]?.length > 0))
                                    ? item?.[e?.key] : <span style={{ visibility: 'hidden' }}>.</span>}
                                </div>
                                {item?.compatitorProducts?.map((subRowData: any) =>
                                    <div style={{ marginTop: 12 }}>
                                        {idKey == 0 && <span style={{ fontSize: 10.7, color: '#1C193999' }}>competitor product: &nbsp;</span>}
                                        <span style={{ color: '#494761' }}>{(subRowData?.[subData?.[idKey]] != null && (subRowData?.[subData?.[idKey]] >= 0 ||
                                            subRowData?.[subData?.[idKey]]?.length > 0)) ?
                                            subRowData?.[subData?.[idKey]]
                                            :
                                            <span style={{ visibility: 'hidden' }}>.</span>
                                        }</span>
                                    </div>)}
                            </td>
                        )}
                    </tr>
                }
                )}
            </table>
        </div>
    }

    return (
        <div className="applicationModal">
            {!isViewBillsPopup &&
                <div style={{ padding: '0px 22px 0px 22px' }} className="applicationView">
                    <div style={{ position: 'absolute', top: 20, right: 70 }}>
                        {!screenLoader &&
                            <>
                                {isActioned ?
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                setTimeout(() => {
                                                    downloadPage()
                                                }, 1000)
                                            }
                                            style={{
                                                backgroundColor: '#322b7c'
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </div>
                                    :
                                    <div >
                                        <Button
                                            variant="outlined"
                                            style={{ marginRight: 40 }}
                                            onClick={() => requestClickHandler(false)}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => requestClickHandler(true)}
                                            style={{ background: '#322b7c' }}
                                        >
                                            {`Approve Application`}
                                        </Button>
                                    </div>}
                            </>
                        }
                    </div>
                    <div id='divToPrint'>
                        <div>{renderHeading(`Check Application`, styles.headerTitle)}</div>
                        <div style={{ marginTop: 40 }} className="approvalStatus">
                            {renderHeading("Approval Status", styles.subHeading)}
                            <div style={{ marginBottom: 20 }}>{CustomTable(approvalColData, approvalRowData, {
                                rowCellItem: 'rowCellItem', headerCellItem: 'headerCellItemApplication',
                            })}</div>
                        </div>
                        <div style={{ marginTop: 40 }}>
                            {renderHeading("Request Details", styles.subHeading)}
                            <Card style={{ padding: 10, marginTop: 20 }}>
                                <Grid container spacing={2}>
                                    {requestDetailData?.map((data: any) =>
                                        <Grid item xs={4}>
                                            <Item>{renderCardLabel(data?.label, data?.value)}</Item>
                                        </Grid>)}
                                </Grid>
                            </Card>
                        </div>
                        <div style={{ marginTop: 40 }} className="productDetail">
                            {renderHeading("Product Details", styles.subHeading)}
                            <div style={{ marginBottom: 20 }}>
                                {
                                    renderProductDetails(productDetailCol, productDetailRow)
                                }</div>
                        </div>
                        {supplyBillsRow && supplyBillsRow?.length > 0 && <div style={{ marginTop: 40 }} className="supplyBills">
                            {renderHeading("Supply Bills", styles.subHeading)}
                            <div style={{ marginBottom: 20 }}>{CustomTable(supplyBillsCol, supplyBillsRow, { rowCellItem: 'rowCellItem', headerCellItem: 'headerCellItem' })}</div>
                        </div>}
                        <div style={{ marginTop: 40 }} className="remarkList">
                            {renderHeading("Remarks", styles.subHeading)}
                            <div style={{ marginBottom: 20 }}>{CustomTable(remarkCol, remarkRow, { rowCellItem: 'rowCellItem', headerCellItem: 'headerCellItem' })}</div>
                        </div>
                        <div style={{ marginTop: 20, maxHeight: 66.7 }}>
                            {!isActioned && <div style={{ width: '100%' }} className="applicationRemark">
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Enter your Remarks"
                                    multiline
                                    rows={1}
                                    value={remarkText}
                                    onChange={e => setremarkText(e.target.value)}
                                    style={{ width: '100%' }}
                                    inputRef={remarkinputRef}
                                />
                            </div>}
                        </div>
                    </div>
                    {!screenLoader && isActioned &&
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    setTimeout(() => {
                                        downloadPage()
                                    }, 1000)}
                                style={{
                                    backgroundColor: '#322b7c'
                                }}
                            >
                                Download
                            </Button>
                        </div>
                    }
                    <div style={{ height: 40 }} />
                </div>
            }
            {isViewBillsPopup && <div className="cnSupplyBills">
                <div style={{ padding: '0px 22px 0px 22px' }}>
                    {renderHeading("Supply Bills", styles.subHeading)}
                    <SupplyBillsModal supplyData={props?.selectedData?.requestClaimProductBatches} />
                </div>
            </div>}
        </div>
    )
};

const SupplyBillsModal = (props: any) => {
    const [groupedBillsData, setgroupedBillsData] = useState<any>([])
    const dispatch = useDispatch();
    const billsDownload = (filePath: string) => {
        dispatch(
            fileDownloadCreator({
                fileName: filePath
            }))
    }

    useEffect(() => {
        const grouped = props?.supplyData?.reduce((result: any, item: any) => {
            const key = item.invoiceNumber;
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(item);
            return result;
        }, {});
        setgroupedBillsData(grouped)
    }, [props?.supplyData]);

    const fileView = async (fileName: any) => {
        try {
            const response: any = await fileDownload(
                `fileoperations/downloaduri?fileName=${fileName}`
            );
            if (response?.status == 200) {
                openImage(response?.data)
            } else {
                ToasterService.showToaster(
                    `Something went wrong`,
                    ToastType.ERROR,
                )
            }
        } catch (err) {
            console.log('err...', err);
            ToasterService.showToaster(
                `Something went wrong`,
                ToastType.ERROR,
            )
        }
    }

    const openImage = (imgUrl: string) => {
        function isPDFUrl(url: string) {
            return url.includes('.pdf');
        }
        // Check if the new tab/window was successfully created
        if (isPDFUrl(imgUrl)
        ) {
            window.open(imgUrl, '_blank');
        } else {
            const newTab = window.open();
            if (newTab) {
                // Define the HTML content you want to display in the new tab
                const htmlContent = `
                    <!DOCTYPE html>
                    <html>
                    <head>
                        <title>View Bill</title>
                    </head>
                    <body>
                        
                        <img src='${imgUrl}'
                        alt="Image" width="60%" height="60%"  />
                    </body>
                    </html>
                `;
                newTab.document.write(htmlContent);
                // Close the document for writing to make it a complete HTML document
                newTab.document.close();
            }
            else {
                ToasterService.showToaster(
                    `Pop-up blockers may prevent opening a new tab. Please allow pop-ups for this site.`,
                    ToastType.WARNING,
                )
            }
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <table style={{ backgroundColor: 'white', width: '100%' }} className="spaced-table">
                <tr style={{ width: '100%' }}>
                    <th className="tableHeader" style={{ width: '10%', textAlign: 'start', textTransform: 'uppercase' }}>Invoice No.</th>
                    <th>
                        <div className="headerContent">
                            <th className="tableHeader" style={{ width: '10%', textAlign: 'left' }}>Bill No.</th>
                            <th className="tableHeader" style={{ width: '20%', textAlign: 'left' }}>Bill Date</th>
                            <th className="tableHeader" style={{ width: '60%', textAlign: 'left' }}>Bill Copy</th>
                            <th className="tableHeader" style={{ width: '10%', textAlign: 'left' }}></th>
                        </div></th>
                </tr>
                {
                    groupedBillsData && Object.keys(groupedBillsData)?.map((billKeys: any) =>
                        <tr>
                            <td style={{ width: '10%', display: 'flex' }}>{billKeys}</td>
                            <td className="rowBox">
                                {groupedBillsData?.[billKeys]?.map((billData: any) => <div className="innerDiv" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <td style={{ width: '10%', textAlign: 'left' }}>{billData?.billNumber}</td>
                                    <td style={{ width: '20%', textAlign: 'left' }}> {billData?.billDate ? moment(billData?.billDate).format('DD / MM / YYYY') : ''}</td>
                                    <td style={{ width: '60%', textAlign: 'left' }} className="linkText">{billData?.billFileName ? (billData?.billFileName?.length > 60 ? `${billData?.billFileName.slice(0, 57)}...` : billData?.billFileName) : ''}</td>
                                    <td style={{ width: '10%', textAlign: 'left' }}>
                                        <span>
                                            <span style={{ display: 'flex' }}>
                                                <VisibilityIcon
                                                    onClick={() => fileView(billData?.billFilePath)}
                                                    style={{ cursor: 'pointer', color: '#322B7C', marginRight: 10, width: 20, height: 20 }}
                                                />
                                                <img
                                                    onClick={() => billsDownload(billData?.billFilePath)}
                                                    src={DownloadIcon}
                                                    style={{ cursor: 'pointer', width: 20, height: 20 }}
                                                ></img>
                                            </span>
                                        </span>
                                    </td>
                                </div>)}
                            </td>
                        </tr>)}
            </table>
        </div>
    )
}

export default CheckApplication;

