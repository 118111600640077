import { createSelector } from "@reduxjs/toolkit";

const navData = (state: any) => state.adminState.nav;
const getNavTitle = createSelector([navData], (nav) => nav.title);
const getNavTabNumber = createSelector([navData], (nav) => nav.tabNumber);

export const adminSelector = {
  getNavTitle: () => getNavTitle, 
  getNavTabNumber: () => getNavTabNumber
};
