import { getAccessToken } from "@app/helper/login";
import env from "@app/env.json";
import axios from "axios";

export const adminConfigHelper = {};

const client = axios.create();
client.defaults.timeout = env.API_TIMEOUT || 60000;

const getNetworkResponse = async (config: any) => {
    return client(config)
        .then(async function (response: any) {
            return response;
        })
        .catch(async (error: any) => {
            return error.response || error.message;
        });
};

export const fileDownload = async (url: any, fileName?: any) => {
    const accessToken = await getAccessToken();
    const config = {
        baseURL: env.API_HOST,
        method: "GET",
        url,
        data: {
            blobName: fileName,
        },
        responseType: "application/json",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };
    return getNetworkResponse(config);
};

export const API_CALL = async (url: any, requestData?: any, type?: 'GET' | 'POST' | 'PUT') => {
    const accessToken = await getAccessToken();
    const config = {
        baseURL: env.API_HOST,
        method: type,
        url,
        data: requestData,
        responseType: "application/json",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };
    return getNetworkResponse(config);
};
