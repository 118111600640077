import { routeStateActions } from '@app/router/redux';
import { navbarComponentName } from '@app/router/redux/routeHandler';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Grid, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { creditNoteManagemnetSelector, updateSummaryDashboardCreator } from '../../redux';
import { appSelector } from '@app/store/selectors';
import CustomTable from '../../ui-component/CustomTable';
import './index.css';
import FilterHeader from '../../ui-component/FilterHeader/FilterHeader';
import moment from 'moment';
import { sizePxToEm } from "@app/common/constants";


const styles = {
  subContent: { marginTop: 15 },
}

const Summary = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(appSelector.getUserInfo());
  const summaryDashboard = useSelector(creditNoteManagemnetSelector.getsummaryDashboard(), shallowEqual);
  const adminusergroup = useSelector(
    creditNoteManagemnetSelector.getadminusergroup(),
    shallowEqual,
  );

  const [summaryCardData, setsummaryCardData] = useState<any>([])

  const [stateClearFilter, setclearFilter] = useState(false);

  const clearFilters = () => {
    if (stateClearFilter) { setclearFilter(false) } else { setclearFilter(true) }
  };

  useEffect(() => {
    callSummary()
  }, [])

  const callSummary = (payload?: any) => {
    dispatch(
      updateSummaryDashboardCreator({
        pageNumber: 1,
        requestTypeId: 2,
        rowsPerPage: 1000,
        ...payload
      }));
  }

  useEffect(() => {
    if (summaryDashboard) {
      let cardDataContainer: any = []
      cardsData?.map((cardData: any) => {
        const cardConfig = summaryDashboard[cardData?.cardKey];
        cardDataContainer.push({
          ...cardData,
          count: cardConfig?.totalOrders || 0,
          salesValue: cardConfig?.salesValue || 0,
          gstValue: cardConfig?.gstValue || 0,
          discountValue: cardConfig?.avgExtraDiscount || 0,
        })
      })
      setsummaryCardData(cardDataContainer);
    } else {
      setsummaryCardData(cardsData);
    }
    doctorDetailHandler(summaryDashboard?.doctorsSummary);
    stocklistDetailHandler(summaryDashboard?.stockistsSummary)
    productDetailHandler(summaryDashboard?.productsSummary)
  }, [summaryDashboard])

  const [doctorDetailCol, setdoctorDetailCol] = useState<any>();
  const [doctorDetailRow, setdoctorDetailRow] = useState<any>();
  const [stocklistDetailCol, setstocklistDetailCol] = useState<any>();
  const [stocklistDetailRow, setstocklistDetailRow] = useState<any>();
  const [productDetailCol, setproductDetailCol] = useState<any>();
  const [productDetailRow, setproductDetailRow] = useState<any>();

  const zhoSubHeader = [
    { name: 'Total Order', key: 'totalOrders', width: '33%' },
    { name: 'Sales Value', key: 'salesValue', width: '33%' },
    { name: 'Avg, Extra Discount', key: 'avgExtraDiscount', postText: '%', width: '33%' },
  ]
  const potentialSubHeader = [
    { name: 'Total Order', key: 'totalOrders', width: '33%' },
    { name: 'Sales Value', key: 'salesValue', width: '33%' },
    { name: 'Avg, Extra Discount', key: 'avgExtraDiscount', postText: '%', width: '33%' },
  ]

  const doctorDetailHandler = (dataProps: any) => {
    const doctorColumn = [
      { name: 'Doctor', key: 'doctorName', type: 'containImg', imgUrl: '', width: '20%' },
      { name: 'Support', key: 'support', width: '10%', headerCellClass: 'textAlignLeft' },
      { name: 'Potential', key: 'potential', width: '10%', headerCellClass: 'textAlignLeft' },
      { name: 'Credit Note', key: 'creditNoteOrders', type: 'subheader', subHeaderColumn: potentialSubHeader, backgroundColor: '#D5E2E733', width: '30%' },
      { name: 'ZHO', key: 'zhoOrders', type: 'subheader', subHeaderColumn: zhoSubHeader, backgroundColor: '#D5E2E766', width: '30%' },
    ]
    setdoctorDetailCol(doctorColumn);
    if (!dataProps) return
    // Formatted number to 2 decimal digit
    const values = structuredClone(dataProps?.data)
    values?.forEach((e: any) => {
      if (e?.creditNoteOrders?.avgExtraDiscount) {
        e.creditNoteOrders.avgExtraDiscount = e?.creditNoteOrders?.avgExtraDiscount ? Number(e?.creditNoteOrders?.avgExtraDiscount).toFixed(2) : '0.00'
      }
      if (e?.zhoOrders?.avgExtraDiscount) {
        e.zhoOrders.avgExtraDiscount = e?.zhoOrders?.avgExtraDiscount ? Number(e?.zhoOrders?.avgExtraDiscount).toFixed(2) : '0.00'
      }
    });
    ///
    setdoctorDetailRow(values)
  }

  const stocklistDetailHandler = (dataProps: any) => {
    const stockColumn = [
      { name: 'STOCKIST', key: 'stockistName', width: '25%', cellClass: 'textAlignCenter' },
      { name: 'Credit Note', key: 'creditNoteOrders', type: 'subheader', subHeaderColumn: potentialSubHeader, backgroundColor: '#D5E2E733', width: '40%' },
      { name: 'ZHO', key: 'zhoOrders', type: 'subheader', subHeaderColumn: zhoSubHeader, backgroundColor: '#D5E2E766', width: '35%' },
    ]
    setstocklistDetailCol(stockColumn);
    if (!dataProps) return
    // Formatted number to 2 decimal digit
    const values = structuredClone(dataProps?.data)
    values?.forEach((e: any) => {
      if (e?.creditNoteOrders?.avgExtraDiscount) {
        e.creditNoteOrders.avgExtraDiscount = e?.creditNoteOrders?.avgExtraDiscount ? Number(e?.creditNoteOrders?.avgExtraDiscount).toFixed(2) : '0.00'
      }
      if (e?.zhoOrders?.avgExtraDiscount) {
        e.zhoOrders.avgExtraDiscount = e?.zhoOrders?.avgExtraDiscount ? Number(e?.zhoOrders?.avgExtraDiscount).toFixed(2) : '0.00'
      }
    });
    ///
    setstocklistDetailRow(values)
  }

  const productDetailHandler = (dataProps: any) => {
    const productColumn = [
      { name: 'Product Name', key: 'productName', cellClass: 'textAlignCenter' },
      { name: 'Credit Note', key: 'creditNoteOrders', type: 'subheader', subHeaderColumn: potentialSubHeader, backgroundColor: '#D5E2E733' },
      { name: 'ZHO', key: 'zhoOrders', type: 'subheader', subHeaderColumn: zhoSubHeader, backgroundColor: '#D5E2E766' },
    ]
    setproductDetailCol(productColumn);
    if (!dataProps) return
    // Formatted number to 2 decimal digit
    const values = structuredClone(dataProps?.data)
    values?.forEach((e: any) => {
      if (e?.creditNoteOrders?.avgExtraDiscount) {
        e.creditNoteOrders.avgExtraDiscount = e?.creditNoteOrders?.avgExtraDiscount ? Number(e?.creditNoteOrders?.avgExtraDiscount).toFixed(2) : '0.00'
      }
      if (e?.zhoOrders?.avgExtraDiscount) {
        e.zhoOrders.avgExtraDiscount = e?.zhoOrders?.avgExtraDiscount ? Number(e?.zhoOrders?.avgExtraDiscount).toFixed(2) : '0.00'
      }
    });
    ///
    setproductDetailRow(values)
  }

  const [selectedTab, setTabValue] = useState(0);

  const handleTabChange = (_event: any, newValue: number): void => {
    setTabValue(newValue);
  };

  const isPMTUser = adminusergroup?.adminGroup == 'PMT' ? true : false;
  const isCNUser = adminusergroup?.adminGroup == 'CN' ? true : false;
  const isFCUser = adminusergroup?.adminGroup == 'FC' ? true : false;

  const SummaryCard = (props: any) => {
    const { borderColor = '', count = 0, headerText = '', salesValue = 0, gstValue = '', discountValue = '' } = props;
    return (
      <Card style={{
        borderRadius: 20, borderColor: borderColor, border: `1px solid ${borderColor}`
      }}>
        <CardContent>
          <Typography sx={{ fontSize: sizePxToEm(22.7) }} color={borderColor}>
            {count}
          </Typography>
          <Typography sx={{ fontSize: sizePxToEm(12.7) }} color={borderColor}>
            {String(headerText).toUpperCase()}
          </Typography>
          <Grid container spacing={1} style={styles.subContent} className='subContent'>
            <Grid xs={4} item>
              <Typography className='subContentHeader' style={{ fontSize: sizePxToEm(16) }}>
                {Number(salesValue).toFixed(0) ?? ''}
              </Typography>
              <Typography className='subContentValue' style={{ fontSize: sizePxToEm(10.7) }}>
                Sales Value (in ₹)
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography className='subContentHeader' style={{ fontSize: sizePxToEm(12.7), display: 'flex', justifyContent: 'center' }}>
                {Number(gstValue).toFixed(0) ?? ''}
              </Typography>
              <Typography className='subContentValue' style={{ fontSize: sizePxToEm(9.3), display: 'flex', justifyContent: 'center' }} >
                GST (in ₹)
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography className='subContentHeader' style={{ fontSize: sizePxToEm(12.7), display: 'flex' }} >
                {Number(discountValue).toFixed(0) ?? ''}{'%'}
              </Typography>
              <Typography className='subContentValue' style={{ fontSize: sizePxToEm(9.3) }}>
                Average extra discount
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  const cardsData = [{
    cardKey: 'totalOrders', borderColor: '#165D92', count: '-', headerText: 'Total orders',
    salesValue: '-', gstValue: '-', discountValue: '-'
  },
  {
    cardKey: 'creditNoteOrders', borderColor: '#50B3C2', count: '-', headerText: 'Credit note orders',
    salesValue: '-', gstValue: '-', discountValue: '-'
  },
  {
    cardKey: 'zhoOrders', borderColor: '#E6B19C', count: '-', headerText: 'ZHO orders',
    salesValue: '-', gstValue: '-', discountValue: '-'
  }]

  const applyFilters = (filterData: any) => {
    const { fromDate, toDate, mr, stock, division, Hq } = filterData
    callSummary({
      fromDate: fromDate ? moment(fromDate).format('MM-DD-YYYY') : null,
      toDate: toDate ? moment(toDate).format('MM-DD-YYYY') : null,
      mrIds: mr,
      stockistIds: stock,
      divisionIds: division,
      hqIds: Hq
    })
  };

  return (
    <div style={{ padding: '0px 20px 20px 20px' }}>
      <FilterHeader
        clickFilterHandler={(data: any) => applyFilters(data)}
        clearFilterClick={stateClearFilter}
        filterType={{
          isToDate: true,
          isFromDate: true,
          isHQ: [isFCUser, isCNUser].includes(true),
          isStock: [isFCUser, isPMTUser, isCNUser].includes(true),
          isMr: [isPMTUser].includes(true),
        }}
        multipleType={{
          isHQ: [isFCUser, isCNUser].includes(true),
          isStock: [isFCUser, isPMTUser, isCNUser].includes(true),
          isMr: [isPMTUser].includes(true),
        }}
        isHeaderStyle={false}
      />
      <Grid container display={"flex"} spacing={2} style={{ marginTop: 10 }}>
        {
          summaryCardData?.map((cardData: any) =>
            <Grid item xs={4}>
              {SummaryCard(cardData)}
            </Grid>
          )
        }
      </Grid>
      <Box sx={{ borderBottom: 0, borderColor: 'divider', marginTop: 4 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab
            label={'Doctors'}
            value={0}
          />
          <Tab
            label={'Stockists'}
            value={1}
          />
          <Tab
            label={'Products'}
            value={2}
          />
        </Tabs>
      </Box>
      <div style={{ overflow: 'auto', height: 400 }} className={`summaryPageTable`}>
        {selectedTab == 0 ? CustomTable(doctorDetailCol, doctorDetailRow?.concat(doctorDetailRow), { rowCellItem: 'rowCellItem1', headerCellItem: 'headerCellItem1' }) :
          selectedTab == 1 ? CustomTable(stocklistDetailCol, stocklistDetailRow, { rowCellItem: 'rowCellItem1', headerCellItem: 'headerCellItem1' }) :
            selectedTab == 2 ? CustomTable(productDetailCol, productDetailRow, { rowCellItem: 'rowCellItem1', headerCellItem: 'headerCellItem1' }) : null
        }
      </div>
    </div>
  );
};

export default Summary;
