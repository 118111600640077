import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  TableRow,
  TableCell,
  Divider,
  IconButton,
  Grid,
  Button,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@app/assets/svg/edit-icon";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { routeStateActions } from "@app/router/redux";
import { navbarComponentName } from "@app/router/redux/routeHandler";
import {
  directoryActions,
  actions,
  directorySelector,
  directoryNavActionHandlerCreator,
} from "../../redux";

import {
  hoContactCategorySelector,
  hoContactCategoryListCreator,
} from "./redux";

import Popup from "../../components/Popup";
import NetworkService from "@app/services/network/NetworkService";
import { API_PATH, TOAST_MESSAGES } from "@app/common/constants";
import TableList from "../../components/TableList";
import {
  directoryPageLists,
  directoryUpdateCurrentPageCreator,
} from "../../redux/slice";
import { ToasterService } from "@app/services";
import { ToastType } from "@app/services/toaster";

const columns = ["Category", "", ""];
const initialHoContactCategory = {
  id: 0,
  name: "",
  isActive: 1,
};

const HOContactCategory = () => {
  const [isUpdatingTab, setIsUpdatingTab] = useState(false);
  const [updatingError, setUpdatingError] = useState<null | string>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [
    updatedInitialHoContactCategoryData,
    setUpdatedInitialHoContactCategoryData,
  ] = useState(initialHoContactCategory);
  const [addHoContactCategoryData, setAddHoContactCategoryData] = useState<any>(
    updatedInitialHoContactCategoryData
  );
  const dispatch = useDispatch();
  const hoContactCategory: any = useSelector(
    hoContactCategorySelector.gethocontactcategorydetails(),
    shallowEqual
  );
  const isActive = useSelector(directorySelector.getStatus, shallowEqual);
  const search = useSelector(directorySelector.getNavSearch(), shallowEqual);

  const modal: any = useSelector(
    directorySelector.getDirectoryModal(),
    shallowEqual
  );

  const showAddTabModal = useMemo(
    () => modal?.show && modal?.type === actions.addTab,
    [modal]
  );

  const loading: any = useSelector(
    directorySelector.getLoading(),
    shallowEqual
  );

  const isTabsFetching = useMemo(
    () =>
      loading === API_PATH.directoryConfiguration.hoContactCategory &&
      (!hoContactCategory || hoContactCategory.length === 0),
    [loading, hoContactCategory]
  );

  const isUpdatingData = useMemo(
    () => loading === API_PATH.directoryConfiguration.hoContactCategory,
    [loading]
  );

  const isEmpty = useMemo(
    () =>
      !isTabsFetching && (!hoContactCategory || hoContactCategory.length === 0),
    [isTabsFetching, hoContactCategory]
  );

  const cancelAddTabModal = useCallback(() => {
    setIsEditable(false);
    dispatch(directoryNavActionHandlerCreator({ show: false, type: null }));
    setUpdatedInitialHoContactCategoryData(initialHoContactCategory);
    setAddHoContactCategoryData(updatedInitialHoContactCategoryData);
    setUpdatingError(null);

  }, []);

  const tabEditHandler = useCallback((event) => {
    const contact = JSON.parse(event.currentTarget.dataset.current);
    setIsEditable(true);
    setUpdatingError(null);
    setUpdatedInitialHoContactCategoryData({
      id: contact.id,
      name: contact.name,
      isActive: contact.isActive,
    });
    dispatch(
      directoryNavActionHandlerCreator({ show: true, type: actions.addTab }),
    );
  }, []);

  const onLoadMoreHandler = useCallback((index: number) => {
    dispatch(
      hoContactCategoryListCreator({
        index,
        isActive: isActive === 1,
        query: search?.query,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      hoContactCategoryListCreator({
        index: 1,
        isActive: isActive === 1,
        query: search?.query,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, search]);

  useEffect(() => {

    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.directoryConfiguration
      )
    );
    dispatch(
      directoryActions.updateNavData({
        title: "HO Contact Category",
        action: { title: "Add New", type: actions.addTab },
        search: { placeholder: "Search here", query: "" },
        status: { show: true, value: true },
      })
    );
    dispatch(
      directoryUpdateCurrentPageCreator(directoryPageLists.hoContactCategory)
    );

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      cancelAddTabModal();
    };
  }, []);

  useEffect(() => {
    setAddHoContactCategoryData(updatedInitialHoContactCategoryData);
  }, [updatedInitialHoContactCategoryData]);

  const onFormUpdateHandler = (data: any) => {
    if (updatingError) {
      setUpdatingError(null);
    }
    setAddHoContactCategoryData({
      ...addHoContactCategoryData,
      ...data,
    });
  };

  const onChangeCategory = useCallback((event) => {
    onFormUpdateHandler({ name: event.target.value })
  }, [onFormUpdateHandler])

  const onChangeActiveCheckBox=useCallback((event)=>{
    onFormUpdateHandler({
        isActive: event.target.checked ? 1 : 0,
    })
  },[onFormUpdateHandler])

  const getAddHoContactCategoryData=useCallback(()=>{
    setAddHoContactCategoryData(updatedInitialHoContactCategoryData)
  },[updatedInitialHoContactCategoryData]);
  const onAddOrUpdateTabHandler = async (e: any) => {
    e.preventDefault();
    if (addHoContactCategoryData.name?.toString().trim()) {
      setIsUpdatingTab(true);

      const updatedInitialData = { ...addHoContactCategoryData };
      try {
        const response = await NetworkService.post(
          API_PATH.directoryConfiguration.updateHoContactCategory,
          updatedInitialData,
          {}
        );
        setIsUpdatingTab(false);
        if (response.status !== 200) {
          ToasterService.showToaster(
            isEditable
              ? TOAST_MESSAGES.UNSUCCESSFULL_UPDATE
              : TOAST_MESSAGES.UNSUCCESSFULL_ADD,
            ToastType.ERROR
          );
          setUpdatingError(response.data.description);
        } else {
          ToasterService.showToaster(
            isEditable
              ? TOAST_MESSAGES.SUCCESSFULLY_UPDATED
              : TOAST_MESSAGES.SUCCESSFULLY_ADD,
            ToastType.SUCCESS
          );
          dispatch(
            hoContactCategoryListCreator({
              index: 1,
              isActive: isActive === 1,
              query: search?.query,
            })
          );
          cancelAddTabModal();
        }
      } catch (error) {
        setIsUpdatingTab(false);
      }
    } else {
      setUpdatingError("Please fill all required fields!!");
    }
  };

  if (isTabsFetching) {
    return (
      <Box justifyContent={"center"} display="flex" sx={{ mt: 2 }}>
        <CircularProgress sx={{ color: "primary.main" }} disableShrink />
      </Box>
    );
  }

  return (
    <>
      <TableList
        columns={columns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}
      >
        {hoContactCategory && hoContactCategory.length > 0
          ? hoContactCategory?.map((contact: any, index: number) => (
            <>
              <TableRow key={contact.id}>
                <TableCell>{contact.name}</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">
                  <IconButton
                    sx={{ p: 0 }}
                    data-current={JSON.stringify(contact)}
                    onClick={tabEditHandler}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
              <Divider
                component={TableRow}
                variant="fullWidth"
                sx={{ height: 5, border: 0 }}
              />
            </>
          ))
          : null}
      </TableList>

      <Popup
        open={!!showAddTabModal}
        cancelPopup={cancelAddTabModal}
        onClose={cancelAddTabModal}
        isLoading={isUpdatingTab}
        error={updatingError}
        title={isEditable ? "Edit Contact Category" : "Add Contact Category"}
      >
        <form onSubmit={onAddOrUpdateTabHandler}>
          <Box sx={{ mb: 3 }}>
            <InputLabel variant="standard" required>
              Category
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Text"
              value={addHoContactCategoryData.name}
              required
              type="text"
              onChange={onChangeCategory}
              // onChange={(event) =>
              //   onFormUpdateHandler({ name: event.target.value })
              // }
              inputProps={{
                maxlength: 30,
                minlength: 2,
              }}
            />
          </Box>
          <Box sx={{ mb: 11 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addHoContactCategoryData.isActive === 1}
                  onChange={onChangeActiveCheckBox}
                />
              }
              label="Is Active"
              labelPlacement="top"
            />
          </Box>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item mr={2}>
              <Button
                variant="outlined"
                onClick={getAddHoContactCategoryData}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Popup>
    </>
  );
};

export default HOContactCategory;
