import { API_PATH } from '@app/common/constants';
import { adminActions } from '@app/screens/admin-configuration/redux';
import { ToasterService } from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import { ToastType } from '@app/services/toaster';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  MRListCreator,
  adminusergroupCreator,
  bulkUploadFilesCreator,
  creditNoteManagementActions,
  divisionListCreator,
  editSupplyCreator,
  getDashboardCountCreator,
  getRequestDetailsCreator,
  getRequestsForCNCreator,
  markFlagCreator,
  productListCreator,
  saveApprovalMatrixCreator,
  statehqlistCreator,
  stockListCreator,
  templateFileCreator,
  updateCreditNoteCreatorTypeName,
  updateDiscountListCreator,
  updatePartyProductsCreator,
  updateRequestsForCNCreator,
  updateSummaryDashboardCreator,
  updateproductdiscountCreator,
} from './slice';
import { updateApprovalMatrixCreator, updateRequestStatusCreator, updateRequestClaimStatusCreator, fileDownloadCreator } from '.';

/**
 * Update Admin Nav watcher
 */
export function* updateCreditNoteWatcher() {
  yield takeLatest(updateCreditNoteCreatorTypeName, updateCreditNoteWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* updateCreditNoteWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  const { pageNumber } = action.payload;
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.getapprovalrequests,
      {
        rowsPerPage: 1000,
        ...action.payload
      },
    );
    yield put(creditNoteManagementActions.setscreenLoader(false));
    if (pageNumber === 1) {
      yield put(creditNoteManagementActions.updateCreditNote(response?.data));
    } else {
      yield put(
        creditNoteManagementActions.updateNewCreditNote(response?.data),
      );
    }
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching data!',
      ToastType.ERROR,
    );
  }
}

export function* summaryDashboardWatcher() {
  yield takeLatest(updateSummaryDashboardCreator, summaryDashboardWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* summaryDashboardWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.summaryDashboard,
      action.payload,
    );
    yield put(creditNoteManagementActions.setscreenLoader(false));
    yield put(creditNoteManagementActions.setSummaryDashboard(response?.data));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  }
}

export function* partyProductsWatcher() {
  yield takeLatest(updatePartyProductsCreator, partyProductsWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* partyProductsWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  const { staffPositionId, partyIds } = action.payload;
  try {
    // const response = yield call(
    //   NetworkService.post,
    //   API_PATH.adminConfiguration.partyProducts,
    //   action.payload,
    // );
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.adminuserGetProducts,
      action.payload,
      {}
    );
    yield put(creditNoteManagementActions.setscreenLoader(false));
    yield put(creditNoteManagementActions.setPartyProducts(response?.data));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching Party Products',
      ToastType.ERROR,
    );
  }
}

export function* productListWatcher() {
  yield takeLatest(productListCreator, productListWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* productListWorker(action: any): any {
  ToasterService.showToaster(
    'Something went wrong.',
    ToastType.ERROR,
  );
  console.log('==Depricated API CAll==', action.payload)
  // yield put(creditNoteManagementActions.setscreenLoader(true));
  // try {
  //   const response = yield call(
  //     NetworkService.get,
  //     API_PATH.adminConfiguration.getproductList,
  //     action.payload,
  //     {}
  //   );
  //   yield put(creditNoteManagementActions.setproductList(response?.data));
  //   yield put(creditNoteManagementActions.setscreenLoader(false));
  // } catch (error) {
  //   yield put(creditNoteManagementActions.setscreenLoader(false));
  //   ToasterService.showToaster(
  //     'Something went wrong while fetching Party Products',
  //     ToastType.ERROR,
  //   );
  // }
}

export function* discountListWatcher() {
  yield takeLatest(updateDiscountListCreator, discountListWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* discountListWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  yield put(creditNoteManagementActions.setDiscountList(null));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.discountList,
      action.payload,
    );
    yield put(creditNoteManagementActions.setscreenLoader(false));
    yield put(creditNoteManagementActions.setDiscountList(response?.data));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching Discoun List',
      ToastType.ERROR,
    );
  }
}

export function* approvalMatrixWatcher() {
  yield takeLatest(updateApprovalMatrixCreator, approvalMatrixWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* approvalMatrixWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.approvalMatrix,
      {},
      {},
    );
    yield put(creditNoteManagementActions.setscreenLoader(false));
    yield put(creditNoteManagementActions.setApprovalMatrix(response?.data));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching Approval matrix',
      ToastType.ERROR,
    );
  }
}

export function* saveApprovalMatrixWatcher() {
  yield takeLatest(saveApprovalMatrixCreator, saveApprovalMatrixWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* saveApprovalMatrixWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.saveApprovalMatrix,
      action.payload,
    );
    if (response?.status == 200) {
      ToasterService.showToaster(
        'Records has been updated successfully',
        ToastType.SUCCESS,
      )
    } else {
      if (response?.data?.details?.[0]?.message) {
        ToasterService.showToaster(
          response?.data?.details?.[0]?.message,
          ToastType.WARNING,
        );
      } else {
        ToasterService.showToaster(
          'Something went wrong',
          ToastType.ERROR,
        );
      }
    }
    yield put(creditNoteManagementActions.setscreenLoader(false));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong',
      ToastType.ERROR,
    );
  }
}

export function* updateproductdiscountWatcher() {
  yield takeLatest(updateproductdiscountCreator, updateproductdiscountWorker);
}

function* updateproductdiscountWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.updateproductdiscount,
      action.payload,
    );
    if (response?.status == 200) {
      ToasterService.showToaster(
        `${action.payload?.length > 1 ? 'Products' : 'Product'} discount %age has been updated`,
        ToastType.SUCCESS,
      )
    } else {
      ToasterService.showToaster(
        'Something went wrong',
        ToastType.ERROR,
      );
    }
    yield put(creditNoteManagementActions.setscreenLoader(false));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while',
      ToastType.ERROR,
    );
  }
}

export function* getRequestsForCNWatcher() {
  yield takeLatest(getRequestsForCNCreator, getRequestsForCNWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* getRequestsForCNWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  yield put(creditNoteManagementActions.setRequestsForCN([]));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.getApprovalRequestForCN,
      action.payload,
    );
    yield put(creditNoteManagementActions.setRequestsForCN(response?.data));
    yield put(creditNoteManagementActions.setscreenLoader(false));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching CN Requests',
      ToastType.ERROR,
    );
  }
}

export function* updateRequestsForCNWatcher() {
  yield takeLatest(updateRequestsForCNCreator, updateRequestsForCNWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* updateRequestsForCNWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.updateRequestStatusForCN,
      action.payload,
    );
    if (response?.status == 200) {
      ToasterService.showToaster(
        `${action.payload?.requestIds?.length > 1 ? 'Requests' : 'Request'} has been ${action?.payload?.status === 1 ? 'approved' : 'reject'} successfully`,
        ToastType.SUCCESS,
      )
    } else {
      ToasterService.showToaster(
        'Something went wrong',
        ToastType.ERROR,
      );
    }
    yield put(creditNoteManagementActions.setscreenLoader(false));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching update request for CN',
      ToastType.ERROR,
    );
  }
}

export function* updateRequestStatusWatcher() {
  yield takeLatest(updateRequestStatusCreator, updateRequestStatusWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* updateRequestStatusWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.updateRequestStatus,
      action.payload,
    );
    if (response?.status == 200) {
      ToasterService.showToaster(
        `${action.payload?.statusDetails?.currentStatusId === 1 ?
          'Request has been approved successfully' :
          'Request has been rejected successfully'}`,
        ToastType.Success_Custom_Container,
      );
    } else {
      ToasterService.showToaster(
        'Oops! I made a mistake',
        ToastType.ERROR_Custom_Container,
      );
    }
    yield put(creditNoteManagementActions.setscreenLoader(false));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Oops! I made a mistake',
      ToastType.ERROR_Custom_Container,
    );
  }
}

export function* updateRequestClaimStatusWatcher() {
  yield takeLatest(updateRequestClaimStatusCreator, updateRequestClaimStatusWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* updateRequestClaimStatusWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.updateRequestClaimStatus,
      action.payload,
    );
    yield put(creditNoteManagementActions.setscreenLoader(false));
    if (response?.status == 200) {
      ToasterService.showToaster(
        'Success',
        ToastType.SUCCESS,
      );
    } else {
      ToasterService.showToaster(
        'Something went wrong while fetching update request status',
        ToastType.ERROR,
      );
    }
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching update request status',
      ToastType.ERROR,
    );
  }
}

export function* getDashboardCountWatcher() {
  yield takeLatest(getDashboardCountCreator, getDashboardCountWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* getDashboardCountWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.getdashboardcount,
      {},
      {},
    );
    yield put(creditNoteManagementActions.setscreenLoader(false));
    yield put(creditNoteManagementActions.setDashboardCount(response?.data));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching request Details',
      ToastType.ERROR,
    );
  }
}

export function* adminusergroupWatcher() {
  yield takeLatest(adminusergroupCreator, adminusergroupWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* adminusergroupWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.adminusergroup,
      action.payload,
      {},
    );
    yield put(creditNoteManagementActions.setscreenLoader(false));
    yield put(creditNoteManagementActions.setAdminUserGroup(response?.data));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
  }
}

export function* getRequestDetailsWatcher() {
  yield takeLatest(getRequestDetailsCreator, getRequestDetailsWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* getRequestDetailsWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.getRequestDetails + action.payload,
      {},
      {},
    );
    yield put(creditNoteManagementActions.setscreenLoader(null));
    yield put(creditNoteManagementActions.setRequestDetails(response?.data));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(null));
    ToasterService.showToaster(
      'Something went wrong while fetching request Details',
      ToastType.ERROR,
    );
  }
}

export function* markFlagWatcher() {
  yield takeLatest(markFlagCreator, markFlagWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* markFlagWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  const payloadData: any = {
    requestId: action.payload?.requestId,
    remark: action.payload?.remark,
  }
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.markFlag,
      payloadData,
    );
    if (response?.status == 200) {
      ToasterService.showToaster(
        `${!action.payload?.isFlagged ? 'selected record is mark flagged' : 'selected record is mark unflagged'}`,
        ToastType.SUCCESS,
      );
    } else {
      ToasterService.showToaster(
        'Something went wrong.',
        ToastType.ERROR,
      );
    }
    yield put(creditNoteManagementActions.setscreenLoader(false));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong while fetching mark flag',
      ToastType.ERROR,
    );
  }
}

export function* statehqlistWatcher() {
  yield takeLatest(statehqlistCreator, statehqlistWorker);
}

/**
 * get state
 *
 * @param action
 */
function* statehqlistWorker(action: any): any {
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.statehqlist,
      action.payload,
      {},
    );
    yield put(adminActions.updateLoading(null));
    yield put(creditNoteManagementActions.setstateqList(response?.data));
    yield put(adminActions.updateLoading(null));
  } catch (error) {
    yield put(adminActions.updateLoading(null));
  }
}

///MRList
export function* MRListWatcher() {
  yield takeLatest(MRListCreator, MRListWorker);
}

function* MRListWorker(action: any): any {
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.MRList,
      action.payload,
      {},
    );
    yield put(adminActions.updateLoading(null));
    yield put(creditNoteManagementActions.setMRList(response?.data));
    yield put(adminActions.updateLoading(null));
  } catch (error) {
    yield put(adminActions.updateLoading(null));
  }
}

///StockList
export function* stockListWatcher() {
  yield takeLatest(stockListCreator, stockListWorker);
}

function* stockListWorker(action: any): any {
  try {
    yield put(creditNoteManagementActions.setscreenLoader(true));
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.stockList,
      action.payload,
      {},
    );
    yield put(adminActions.updateLoading(null));
    yield put(creditNoteManagementActions.setstockList(response?.data));
    yield put(creditNoteManagementActions.setscreenLoader(false));
    yield put(adminActions.updateLoading(null));
  } catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    yield put(adminActions.updateLoading(null));
  }
}

///divisionList
export function* divisionListWatcher() {
  yield takeLatest(divisionListCreator, divisionListWorker);
}

function* divisionListWorker(action: any): any {
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.divisionList,
      action.payload,
      {},
    );
    yield put(adminActions.updateLoading(null));
    yield put(creditNoteManagementActions.setdivisionList(response?.data));
    yield put(adminActions.updateLoading(null));
  } catch (error) {
    yield put(adminActions.updateLoading(null));
  }
}

export function* templateFileWatcher() {
  yield takeLatest(templateFileCreator, templateFileWorker);
}

function* templateFileWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.templateDownload,
      action.payload,
      {},
    );
    yield put(creditNoteManagementActions.settemplateFile(response?.data));
    yield put(creditNoteManagementActions.setscreenLoader(false));
    if (response?.status != 200) {
      ToasterService.showToaster(
        'Something went wrong while fetch template',
        ToastType.ERROR,
      );
      yield put(creditNoteManagementActions.setscreenLoader(false));
    }
  }
  catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong',
      ToastType.ERROR,
    );
  }
};

//File download
export function* fileDownloadWatcher() {
  yield takeLatest(fileDownloadCreator, fileDownloadWorker);
}

function* fileDownloadWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const payload = {
      fileName: action.payload?.fileName
    };
    const response = yield call(
      NetworkService.get,
      API_PATH.adminConfiguration.fileDownload,
      payload,
      {},
    );
    if (response?.data) {
      yield put(adminActions.updateLoading(true));
      const link: any = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', `FileName.jpg`);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
    if (response?.status == 200) {
      setTimeout(() => {
        ToasterService.showToaster(
          `${action.payload?.isType == 'prescription' ? 'Prescription' : 'Supply bill'} has been downloaded`,
          ToastType.SUCCESS,
        );
      })
    } else {
      ToasterService.showToaster(
        'Something went wrong',
        ToastType.ERROR,
      );
    }
    yield put(creditNoteManagementActions.setscreenLoader(false));
  }
  catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong',
      ToastType.ERROR,
    );
  }
};

// file upload

export function* bulkUploadFilesWatcher() {
  yield takeLatest(bulkUploadFilesCreator, bulkUploadFilesWorker);
}

function* bulkUploadFilesWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.bulk_upload,
      action.payload,
      {},
    );
    if (response?.status == 200) {
      ToasterService.showToaster(
        'Upload request has been processed',
        ToastType.SUCCESS,
      );
    } else {
      if (response?.data?.details?.[0]?.message) {
        ToasterService.showToaster(
          response?.data?.details?.[0]?.message,
          ToastType.ERROR,
        );
      } else {
        ToasterService.showToaster(
          'Something went wrong while upload the files',
          ToastType.ERROR,
        );
      }
    }
    yield put(creditNoteManagementActions.setscreenLoader(false));
  }
  catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong',
      ToastType.ERROR,
    );
  }
};

export function* editSupplyWatcher() {
  yield takeLatest(editSupplyCreator, editSupplyWorker);
}

function* editSupplyWorker(action: any): any {
  yield put(creditNoteManagementActions.setscreenLoader(true));
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.adminConfiguration.edit_supply,
      action.payload,
      {},
    );
    if (response?.status == 200) {
      ToasterService.showToaster(
        'Supply details has been updated',
        ToastType.SUCCESS,
      );
    } else {
      if (response?.data?.description) {
        ToasterService.showToaster(
          response?.data?.description,
          ToastType.WARNING,
        );
      } else {
        ToasterService.showToaster(
          'Something went wrong',
          ToastType.ERROR,
        );
      }
    }
    yield put(creditNoteManagementActions.setscreenLoader(false));
  }
  catch (error) {
    yield put(creditNoteManagementActions.setscreenLoader(false));
    ToasterService.showToaster(
      'Something went wrong',
      ToastType.ERROR,
    );
  }
};
