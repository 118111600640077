import { createSelector } from "@reduxjs/toolkit";

const creditNote = (state: any) => state.creditNoteManagementState.creditNote;

const getcreditNote = createSelector([creditNote], (_creditNote) => _creditNote);

const summaryDashboard = (state: any) => state.creditNoteManagementState.summaryDashboard;

const getsummaryDashboard = createSelector([summaryDashboard], (_summaryDashboard) => _summaryDashboard);

const getrequestDetail = (state: any) => state.creditNoteManagementState.requestDetails;

const getrequestDetails = createSelector([getrequestDetail], (_getrequestDetail) => _getrequestDetail);

const getapprovalMatrixState = (state: any) => state.creditNoteManagementState.approvalMatrix;

const getapprovalMatrix = createSelector([getapprovalMatrixState], (_approvalMatrix) => _approvalMatrix);

const getcnRequestsState = (state: any) => state.creditNoteManagementState.cnRequests;

const getcnRequests = createSelector([getcnRequestsState], (_getcnRequestsState) => _getcnRequestsState);

const dashboardCountState = (state: any) => state.creditNoteManagementState.dashboardCount;

const getDashboardCount = createSelector([dashboardCountState], (_dashboardCountState) => _dashboardCountState);

const adminusergroupState = (state: any) => state.creditNoteManagementState.adminusergroup;

const getadminusergroup = createSelector([adminusergroupState], (_adminusergroupState) => _adminusergroupState);

const productListState = (state: any) => state.creditNoteManagementState.productList;

const getproductList = createSelector([productListState], (_productList) => _productList);

const partyProducts = (state: any) => state.creditNoteManagementState.partyProducts;

const getpartyProductsList = createSelector([partyProducts], (_partyProducts) => _partyProducts);

const getdiscount = (state: any) => state.creditNoteManagementState.discountList;

const getdiscountList = createSelector([getdiscount], (_getdiscount) => _getdiscount);

const statehqlist = (state: any) => state.creditNoteManagementState.statehqlist;

const getstatehqlist = createSelector([statehqlist], (_statehqlist) => _statehqlist);

const MRList = (state: any) => state.creditNoteManagementState.MRList;

const getMRList = createSelector([MRList], (_MRList) => _MRList);

const stockList = (state: any) => state.creditNoteManagementState.stockList;

const getstockList = createSelector([stockList], (_stockList) => _stockList);

const divisionList = (state: any) => state.creditNoteManagementState.divisionList;

const getdivisionList = createSelector([divisionList], (_divisionList) => _divisionList);

const templateFile = (state: any) => state.creditNoteManagementState.templateFile;

const gettemplateFile = createSelector([templateFile], (_templateFile) => _templateFile);

const screenLoader = (state: any) => state.creditNoteManagementState.screenLoader;

const getscreenLoader = createSelector([screenLoader], (_screenLoader) => _screenLoader);

export const creditNoteManagemnetSelector = {
  getcreditNote: () => getcreditNote,
  getsummaryDashboard: () => getsummaryDashboard,
  getrequestDetails: () => getrequestDetails,
  getapprovalMatrix: () => getapprovalMatrix,
  getcnRequests: () => getcnRequests,
  getDashboardCount: () => getDashboardCount,
  getadminusergroup: () => getadminusergroup,
  getproductList: () => getproductList,
  getpartyProductsList: () => getpartyProductsList,
  getdiscountList: () => getdiscountList,
  getstatehqlist: () => getstatehqlist,
  getMRList: () => getMRList,
  getstockList: () => getstockList,
  getdivisionList: () => getdivisionList,
  gettemplateFile: () => gettemplateFile,
  getscreenLoader: () => getscreenLoader
}
