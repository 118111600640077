import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import CustomTable from "../../ui-component/CustomTable";
import { API_PATH, sizePxToEm } from "@app/common/constants";
import { useEffect, useState } from 'react';
import './index.css'
import { API_CALL } from "@app/screens/admin-configuration/helper";
import { ToasterService } from "@app/services";
import { ToastType } from "@app/services/toaster";

const tableColumn = [{ name: 'User', key: 'roleShortName' }, { name: 'Current Status', key: 'initialStatus', type: 'showActive', activeText: 'Active', inActiveText: 'InActive' }];
const radioButtonHeader = [{ name: 'Proposed Status', key: 'isActive', type: 'radioButton' }];

const ManageUserRole = () => {

  const [workflow1, setworkflow1] = useState<any>([]);
  const [workflow2, setworkflow2] = useState<any>([]);

  const getMatrx = async () => {
    const matrixData = await API_CALL(API_PATH.adminConfiguration.approvalMatrix, null, 'GET');
    if (matrixData?.status == 200 && matrixData?.data) {
      let workFlowData_1: any = [];
      let workFlowData_2: any = []
      matrixData?.data?.map((e: any) => {
        const { roleShortName, id, isActive, roleId, workflowTypeId } = e;
        const formatedData = {
          roleShortName,
          id,
          "WorkflowTypeId": workflowTypeId,
          "RoleId": roleId,
          "initialStatus": isActive,
          'isActive': isActive
        }
        if (workflowTypeId == 1) {
          workFlowData_1.push(formatedData)
        }
        else {
          workFlowData_2.push(formatedData)
        }
      })
      setworkflow1(workFlowData_1);
      setworkflow2(workFlowData_2)
    }
  }

  useEffect(() => {
    getMatrx();
  }, [])

  const onUpdateHandler = (eventType: string, evt: any, data: any) => {
    if (data?.WorkflowTypeId == 1) {
      const workflow1FormatedData = workflow1?.map((e: any) => ({
        ...e,
        isActive: e?.id == data?.id ? evt?.target?.checked : e.isActive
      }))
      setworkflow1(workflow1FormatedData)
    }
    if (data?.WorkflowTypeId == 2) {
      const workflow1FormatedData = workflow2?.map((e: any) => ({
        ...e,
        isActive: e?.id == data?.id ? evt?.target?.checked : e.isActive
      }))
      setworkflow2(workflow1FormatedData)
    }
  }

  const updateMatrix = async (buttonType: number) => {
    const matrixData = await API_CALL(API_PATH.adminConfiguration.saveApprovalMatrix, buttonType === 1 ? workflow1 : workflow2, 'POST');
    if (matrixData?.status == 200) {
      ToasterService.showToaster(
        'Records has been updated successfully',
        ToastType.SUCCESS,
      )
      getMatrx();
    } else {
      if (matrixData?.data?.details?.[0]?.message) {
        ToasterService.showToaster(
          matrixData?.data?.details?.[0]?.message,
          ToastType.WARNING,
        );
      } else {
        ToasterService.showToaster(
          'Something went wrong',
          ToastType.ERROR,
        );
      }
    }
  }

  return (
    <div style={{ padding: 20 }} className="manageUserRole">
      <div id='application_approval'>
        <Typography sx={{ fontSize: sizePxToEm(16.7), fontWeight: '600', color: '#1C1939' }}>
          {'1. CN/ZHO Application Approval User Roles'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '70%' }}>{CustomTable(tableColumn, workflow1, { headerCellItem: 'headerCellItem', tableRowCell: 'tableRowCell' }, null, 300)}</div>
          <div style={{ width: '30%' }}>
            {CustomTable(radioButtonHeader, workflow1, { tableRow: 'tableRow1', tableRowCell: 'tableRowCell1', headerCellItem: 'userRoleTableHeader' }, onUpdateHandler, 200)}
            {workflow1 && workflow1?.length != 0 && <div style={{ marginTop: 20 }} className="updateButton">
              <Button
                variant="contained"
                onClick={() => updateMatrix(1)}
                style={{ backgroundColor: '#322b7c', minWidth: 130 }}
              >
                Update
              </Button>
            </div>}
          </div>
        </div>
        <div>
        </div>

      </div>
      <div id='claim_approval' style={{ marginTop: 40 }}>
        <Typography sx={{ fontSize: sizePxToEm(16.7), fontWeight: '600', color: '#1C1939' }}>
          {'2. CN/ZHO Claim Approval User Roles'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '70%' }}>{CustomTable(tableColumn, workflow2, { headerCellItem: 'headerCellItem', tableRowCell: 'tableRowCell' }, null, 300)}</div>
          <div style={{ width: '30%' }}>
            {CustomTable(radioButtonHeader, workflow2, { tableRow: 'tableRow1', tableRowCell: 'tableRowCell1', headerCellItem: 'userRoleTableHeader' }, onUpdateHandler, 200)}
            <div style={{ marginTop: 20 }} className="updateButton">
              {workflow2 && workflow2?.length != 0 && <Button
                variant="contained"
                onClick={() => updateMatrix(2)}
                style={{ backgroundColor: '#322b7c', minWidth: 130 }}
              >
                Update
              </Button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUserRole;
