import { Box, styled } from "@mui/material";

export const GoButton = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(149, , 165, 0.2)' : 'rgb(1, 4, 9)'
    }`,
}));

export const Styles = {
  filterHeaderBody: {
    margin: '0px 5px 0px 5px',
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    padding: '5px 15px 5px 15px'
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  inputCell: {
    //margin: '5px 0px 5px 5px',
    position: `relative`,
    width: 180,
  } as React.CSSProperties,
  goButton: {
    fontFamily: ["Poppins"].join(","),
    fontSize: 12.7,
    padding: '11px 54px',
    fontWeight: '600',
    cursor: 'pointer',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    backgroundColor: "#322b7c",
    color: "white",
    // outline: "#322b7c",
    border: 0,
    // ':hover': {
    //   border: 0,
    // }
  }
}