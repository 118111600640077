import {useCallback, useEffect, useState} from 'react';
import {Search} from '@mui/icons-material';
import {
  Autocomplete,
  FormControl,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {useSelector, useDispatch,shallowEqual} from 'react-redux';
import {trackEvent} from '@app/utils/analytics';
import {
  EVENT_NAME_ACTIVE_BUTTON,
  EVENT_NAME_SEARCH_BAR,
} from '@app/utils/analytics/constants';
import {componentManagementStateActions} from '@app/screens/component-management/components/redux';
import {
  fetchExpenseTypeAutoSuggestCreator,
  fetchExpenseTypeCreator,
} from '@app/screens/component-management/components/redux/slice';
import {ComponentManagementSelector} from '@app/screens/component-management/components/redux/selectors';
import { AntSwitch } from '@app/components/widgets/AntSwitch/AntSwitch';
import { searchBarStyle } from '@app/components/widgets/searchBarCss/searchBarCss';


export const SearchBarExpenseType = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const isActiveState = useSelector(
    ComponentManagementSelector.getIsActiveState(),
  );
  const resetNavbarVersion = useSelector(
    ComponentManagementSelector.getResetNavbarVersion(),
    shallowEqual,
  );
  const searchText = useSelector(ComponentManagementSelector.getSearchText());
  const expenseTypeInAutoSuggestFilter = useSelector(ComponentManagementSelector.getExpenseTypeInAutoSuggestFilter());
  const autosuggestData = useSelector(
    ComponentManagementSelector.getExpenseTypeInAutoSuggest(),
  );
  
  useEffect(() => {
     dispatch(fetchExpenseTypeAutoSuggestCreator())
  }, [searchText, dispatch]);
  useEffect(() => {
    expenseTypeInAutoSuggestFilter &&
      Object.keys(expenseTypeInAutoSuggestFilter).length > 0 &&
      dispatch(
        fetchExpenseTypeCreator(expenseTypeInAutoSuggestFilter),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseTypeInAutoSuggestFilter]);

 const onChange = useCallback(function (_event: any, value: any) {
  if (!value) {
    dispatch(fetchExpenseTypeCreator(null));
     
   }
   dispatch(
     componentManagementStateActions.setExpenseTypeInAutoSuggestFilter(
       value
     )
   );
},[])

const onOpen = useCallback(() => setOpen(true),[]);
const onClose = useCallback(() =>{setOpen(false);
  dispatch(
    componentManagementStateActions.setExpenseTypeInAutoSuggest([]))},[]);
    const getOptionLabelHandler = useCallback(function (option: any) {
        return option.expenseName
    },[])
    const onChangeAutoComplete = useCallback(function (event: any) {
      trackEvent(EVENT_NAME_SEARCH_BAR, {});
      dispatch(
        componentManagementStateActions.setSearchText(
          event.target.value,
        ),
      );
    },[])
    const activeInactive = useCallback((event: React.ChangeEvent<HTMLInputElement>) =>{
      trackEvent(EVENT_NAME_ACTIVE_BUTTON, {});
               
      dispatch(
        componentManagementStateActions.setIsActiveState(event.target.checked),
      );
      setTimeout(() => {
        dispatch(fetchExpenseTypeCreator(null));
      }, 10);
    },[]);
const renderInput = useCallback(function (params1: any) {
  return(
    <TextField
      onChange={onChangeAutoComplete}
      placeholder={'Search here...'}
      {...params1}
      InputProps={{
        ...params1.InputProps,
        startAdornment: (
          <InputAdornment
            sx={searchBarStyle.padding5}
            position="start"
          >
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <></>
        ),
      }}
      sx={searchBarStyle.textField}
    />
  )
},[])
  return (
    <div>
      <Toolbar sx={searchBarStyle.toolbar}>
        <FormControl sx={searchBarStyle.width60}>
          <Autocomplete
            open={open}key={
              resetNavbarVersion
            }
            onChange={onChange}
            onOpen={onOpen}
            onClose={onClose}
            getOptionLabel={getOptionLabelHandler}
            options={
              autosuggestData && autosuggestData.length > 0
                ? autosuggestData
                : []
            }
            renderInput={renderInput}
          />
        </FormControl>

        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              color={'#322b7c'}
              fontWeight={'800'}
              fontFamily={'Poppins'}
              fontSize={'normal'}
            >
              In-Active
            </Typography>
            <AntSwitch
              onChange={activeInactive}
              checked={isActiveState}
              inputProps={{'aria-label': 'ant design'}}
            />
            <Typography
              color={'#322b7c'}
              fontWeight={'800'}
              fontFamily={'Poppins'}
              fontStyle={'normal'}
            >
              Active
            </Typography>
          </Stack>
        </FormGroup>
      </Toolbar>
    </div>
  );
};
