import { put, takeLatest } from "redux-saga/effects";
import {
  adminActions,
  updateAdminNavDataCreatorTypeName,
} from "./slice";

/**
 * Update Admin Nav watcher
 */
export function* updateAdminNavWatcher() {
  yield takeLatest(
    updateAdminNavDataCreatorTypeName,
    updateAdminNavWorker
  );
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* updateAdminNavWorker(action: any) {
  yield put(adminActions.updateNavData(action.payload));
}
